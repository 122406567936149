import React from "react";
import NoPhoto from "images/no-photo.svg";
export var PlaceholderImage = function PlaceholderImage(_ref) {
  var _ref$alt = _ref.alt,
      alt = _ref$alt === void 0 ? "placeholder" : _ref$alt,
      width = _ref.width,
      height = _ref.height;
  return React.createElement("img", {
    src: NoPhoto,
    alt: alt,
    width: width,
    height: height
  });
};