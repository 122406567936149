import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { TextField } from "@components/molecules";
import { commonMessages } from "@temp/intl";
export var QuantityInput = function QuantityInput(_ref) {
  var disabled = _ref.disabled,
      quantity = _ref.quantity,
      maxQuantity = _ref.maxQuantity,
      onQuantityChange = _ref.onQuantityChange,
      hideErrors = _ref.hideErrors,
      testingContext = _ref.testingContext,
      testingContextId = _ref.testingContextId;

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      isTooMuch = _useState2[0],
      setIsTooMuch = _useState2[1];

  var intl = useIntl();
  useEffect(function () {
    setIsTooMuch(!isNaN(quantity) && quantity > maxQuantity);
  }, [quantity, maxQuantity]);

  var handleQuantityChange = function handleQuantityChange(evt) {
    var newQuantity = parseInt(evt.target.value, 10);

    if (quantity !== newQuantity) {
      onQuantityChange(newQuantity);
    }

    setIsTooMuch(!isNaN(newQuantity) && newQuantity > maxQuantity);
  };

  var quantityErrors = !hideErrors && isTooMuch ? [{
    message: intl.formatMessage(commonMessages.maxQtyIs, {
      maxQuantity: maxQuantity
    })
  }] : undefined;
  return React.createElement(TextField, {
    name: "quantity",
    type: "number",
    label: intl.formatMessage(commonMessages.quantity),
    min: "1",
    value: quantity.toString(),
    disabled: disabled,
    onChange: handleQuantityChange,
    errors: quantityErrors,
    "data-test": testingContext,
    "data-testid": testingContextId
  });
};
QuantityInput.displayName = "QuantityInput";
export default QuantityInput;