import React from "react";
import * as S from "./styles";
export var RichTextContent = function RichTextContent(_ref) {
  var descriptionHtml = _ref.descriptionHtml;
  return React.createElement(React.Fragment, null, (descriptionHtml || descriptionHtml === "") && React.createElement(S.Container, {
    // eslint-disable-next-line react/no-danger
    dangerouslySetInnerHTML: {
      __html: descriptionHtml
    }
  }));
};