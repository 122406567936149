import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _objectSpread from "@babel/runtime/helpers/objectSpread";
import "./scss/index.scss";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { ProductList } from "@components/organisms";
import { TypedRecentProductsQuery } from "@temp/views/Home/queries";
import { RECENT_PRODUCTS_AMOUNT } from "@temp/core/config";
import { useQueryParams } from "@temp/hooks";
import { convertSortByFromString } from "../../core/utils";
import { structuredData } from "../../core/SEO/Homepage/structuredData";

var Page = function Page(_ref) {
  var loading = _ref.loading,
      categories = _ref.categories,
      backgroundImage = _ref.backgroundImage,
      shop = _ref.shop;
  var queryParams = useQueryParams();
  var currentPage = queryParams.page ? Number(queryParams.page) : 1;
  var variables = {
    sortBy: convertSortByFromString("-updated_at"),
    first: RECENT_PRODUCTS_AMOUNT,
    offset: (currentPage ? currentPage - 1 : 0) * RECENT_PRODUCTS_AMOUNT
  };
  return React.createElement(React.Fragment, null, React.createElement("script", {
    className: "structured-data-list",
    type: "application/ld+json"
  }, structuredData(shop)), React.createElement("div", {
    className: "home-page__recent-products"
  }, React.createElement("div", {
    className: "container"
  }, React.createElement("h3", null, React.createElement(FormattedMessage, {
    id: "views.Home.Page.2118354734",
    defaultMessage: "Najnowsze produkty"
  })), React.createElement(TypedRecentProductsQuery, {
    variables: variables
  }, function (recentProducts) {
    var handleLoadMore = function handleLoadMore() {
      return recentProducts.loadMore(function (prev, next) {
        return _objectSpread({}, prev, {
          products: _objectSpread({}, prev.products, {
            edges: [].concat(_toConsumableArray(prev.products.edges), _toConsumableArray(next.products.edges)),
            pageInfo: next.products.pageInfo
          })
        });
      }, {
        offset: variables.offset + recentProducts.data.products.edges.length
      });
    };

    return React.createElement(ProductList, {
      totalCount: recentProducts.data.products.totalCount,
      products: recentProducts.data.products.edges.map(function (edge) {
        return edge.node;
      }),
      loading: recentProducts.loading,
      onLoadMore: handleLoadMore
    });
  }))));
};

export default Page;