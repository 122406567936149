import { defineMessages } from "react-intl";
export var commonMessages = defineMessages({
  search: {
    "id": "intl.commonMessages.search",
    defaultMessage: "szukaj"
  },
  outOfStock: {
    "id": "intl.commonMessages.outOfStock",
    defaultMessage: "Brak na stanie"
  },
  lowStock: {
    "id": "intl.commonMessages.lowStock",
    defaultMessage: "Ostatnie sztuki"
  },
  noItemsAvailable: {
    "id": "intl.commonMessages.noItemsAvailable",
    defaultMessage: "Brak w magazynie"
  },
  noPurchaseAvailable: {
    "id": "intl.commonMessages.noPurchaseAvailable",
    defaultMessage: "Niedostępny do sprzedaży"
  },
  purchaseAvailableOn: {
    "id": "intl.commonMessages.purchaseAvailableOn",
    defaultMessage: "B\u0119dzie dost\u0119pny w sprzeda\u017Cy {date} od {time}"
  },
  youMightLike: {
    "id": "intl.commonMessages.youMightLike",
    defaultMessage: "Może Ci się spodobać"
  },
  choosePaymentMethod: {
    "id": "intl.commonMessages.choosePaymentMethod",
    defaultMessage: "Proszę wybierz sposób płatności."
  },
  provideEmailAddress: {
    "id": "intl.commonMessages.provideEmailAddress",
    defaultMessage: "Proszę podaj adres e-mail."
  },
  account: {
    "id": "intl.commonMessages.account",
    defaultMessage: "Konto"
  },
  myAccount: {
    "id": "intl.commonMessages.myAccount",
    defaultMessage: "Moje konto"
  },
  orderHistory: {
    "id": "intl.commonMessages.orderHistory",
    defaultMessage: "Zamówienia"
  },
  addressBook: {
    "id": "intl.commonMessages.addressBook",
    defaultMessage: "Dane adresowe"
  },
  logOut: {
    "id": "intl.commonMessages.logOut",
    defaultMessage: "Wyloguj"
  },
  firstName: {
    "id": "intl.commonMessages.firstName",
    defaultMessage: "Imię"
  },
  lastName: {
    "id": "intl.commonMessages.lastName",
    defaultMessage: "Nazwisko"
  },
  password: {
    "id": "intl.commonMessages.password",
    defaultMessage: "Hasło"
  },
  quantity: {
    "id": "intl.commonMessages.quantity",
    defaultMessage: "Ilość"
  },
  sku: {
    "id": "intl.commonMessages.sku",
    defaultMessage: "KOD"
  },
  maxQtyIs: {
    "id": "intl.commonMessages.maxQtyIs",
    defaultMessage: "Maksymalna liczba sztuk {maxQuantity}"
  },
  qty: {
    "id": "intl.commonMessages.qty",
    defaultMessage: "Ilość"
  },
  subtotal: {
    "id": "intl.commonMessages.subtotal",
    defaultMessage: "Suma częściowa"
  },
  shipping: {
    "id": "intl.commonMessages.shipping",
    defaultMessage: "Dostawa"
  },
  promoCode: {
    "id": "intl.commonMessages.promoCode",
    defaultMessage: "Kod promocyjny"
  },
  total: {
    "id": "intl.commonMessages.total",
    defaultMessage: "Suma"
  },
  totalPrice: {
    "id": "intl.commonMessages.totalPrice",
    defaultMessage: "Cena całkowita"
  },
  checkout: {
    "id": "intl.commonMessages.checkout",
    defaultMessage: "Złóż zamówienie"
  },
  eMail: {
    "id": "intl.commonMessages.eMail",
    defaultMessage: "Adres e-mail"
  },
  shortEmail: {
    "id": "intl.commonMessages.shortEmail",
    defaultMessage: "Email"
  },
  loading: {
    "id": "intl.commonMessages.loading",
    defaultMessage: "Ładowanie"
  },
  products: {
    "id": "intl.commonMessages.products",
    defaultMessage: "Produkty"
  },
  price: {
    "id": "intl.commonMessages.price",
    defaultMessage: "Cena"
  },
  variant: {
    "id": "intl.commonMessages.variant",
    defaultMessage: "Wariant"
  },
  phone: {
    "id": "intl.commonMessages.phone",
    defaultMessage: "Telefon"
  },
  phoneNumber: {
    "id": "intl.commonMessages.phoneNumber",
    defaultMessage: "Telefon kontaktowy: {phone}"
  },
  showEmail: {
    "id": "intl.commonMessages.showEmail",
    defaultMessage: "Email: {email}"
  },
  save: {
    "id": "intl.commonMessages.save",
    defaultMessage: "Zapisz"
  },
  add: {
    "id": "intl.commonMessages.add",
    defaultMessage: "Dodaj"
  },
  filterHeader: {
    "id": "intl.commonMessages.filterHeader",
    defaultMessage: "FILTRY"
  },
  clearFilterHeader: {
    "id": "intl.commonMessages.clearFilterHeader",
    defaultMessage: "WYCZYŚĆ FILTRY"
  },
  status: {
    "id": "intl.commonMessages.status",
    defaultMessage: "Status"
  },
  cancel: {
    "id": "intl.commonMessages.cancel",
    defaultMessage: "Anuluj"
  },
  home: {
    "id": "intl.commonMessages.home",
    defaultMessage: "Stona Głowna"
  }
});
export var checkoutMessages = defineMessages({
  stepNameAddress: {
    "id": "intl.checkoutMessages.stepNameAddress",
    defaultMessage: "Adres"
  },
  stepNameShipping: {
    "id": "intl.checkoutMessages.stepNameShipping",
    defaultMessage: "Metoda dostawy"
  },
  stepNamePayment: {
    "id": "intl.checkoutMessages.stepNamePayment",
    defaultMessage: "Metoda płatności"
  },
  stepNameReview: {
    "id": "intl.checkoutMessages.stepNameReview",
    defaultMessage: "Podsumowanie"
  },
  addressNextActionName: {
    "id": "intl.checkoutMessages.addressNextActionName",
    defaultMessage: "Wybierz metodę dostawy"
  },
  shippingNextActionName: {
    "id": "intl.checkoutMessages.shippingNextActionName",
    defaultMessage: "Wybierz metodę płatności"
  },
  paymentNextActionName: {
    "id": "intl.checkoutMessages.paymentNextActionName",
    defaultMessage: "Przejdź do podsumowania"
  },
  reviewNextActionName: {
    "id": "intl.checkoutMessages.reviewNextActionName",
    defaultMessage: "Złóż zamówienie"
  },
  addNewAddress: {
    "id": "intl.checkoutMessages.addNewAddress",
    defaultMessage: "Dodaj nowy adres"
  },
  shippingMethod: {
    "id": "intl.checkoutMessages.shippingMethod",
    defaultMessage: "METODA DOSTAWY"
  },
  shippingDropOffPoint: {
    "id": "intl.checkoutMessages.shippingDropOffPoint",
    defaultMessage: "Punkt odbioru"
  },
  billingAddress: {
    "id": "intl.checkoutMessages.billingAddress",
    defaultMessage: "ADRES ROZLICZENIOWY"
  },
  paymentMethod: {
    "id": "intl.checkoutMessages.paymentMethod",
    defaultMessage: "METODA PŁATNOŚCI"
  },
  reviewOrder: {
    "id": "intl.checkoutMessages.reviewOrder",
    defaultMessage: "PODSUMOWANIE ZAMÓWIENIA"
  },
  shippingAddress: {
    "id": "intl.checkoutMessages.shippingAddress",
    defaultMessage: "Adres dostawy"
  },
  continueShopping: {
    "id": "intl.checkoutMessages.continueShopping",
    defaultMessage: "KONTYNUUJ ZAKUPY"
  }
});
export var prodListHeaderCommonMsg = defineMessages({
  sortOptionsClear: {
    "id": "intl.prodListHeaderCommonMsg.sortOptionsClear",
    defaultMessage: "Oczyścić..."
  },
  sortOptionsPrice: {
    "id": "intl.prodListHeaderCommonMsg.sortOptionsPrice",
    defaultMessage: "Cena Niska->Wysoka"
  },
  sortOptionsPriceDsc: {
    "id": "intl.prodListHeaderCommonMsg.sortOptionsPriceDsc",
    defaultMessage: "Cena Wysoka->Niska"
  },
  sortOptionsName: {
    "id": "intl.prodListHeaderCommonMsg.sortOptionsName",
    defaultMessage: "Nazwa rosnąco"
  },
  sortOptionsNameDsc: {
    "id": "intl.prodListHeaderCommonMsg.sortOptionsNameDsc",
    defaultMessage: "Nazwa malejąco"
  },
  sortOptionsUpdatedAt: {
    "id": "intl.prodListHeaderCommonMsg.sortOptionsUpdatedAt",
    defaultMessage: "Ostatnia aktualizacja Rosnąco"
  },
  sortOptionsUpdatedAtDsc: {
    "id": "intl.prodListHeaderCommonMsg.sortOptionsUpdatedAtDsc",
    defaultMessage: "Ostatnia aktualizacja Malejąco"
  }
});
export var paymentStatusMessages = defineMessages({
  notCharged: {
    "id": "intl.paymentStatusMessages.notCharged",
    defaultMessage: "Nie opłacone"
  },
  partiallyCharged: {
    "id": "intl.paymentStatusMessages.partiallyCharged",
    defaultMessage: "Częściowo opłacone"
  },
  fullyCharged: {
    "id": "intl.paymentStatusMessages.fullyCharged",
    defaultMessage: "Opłacone"
  },
  partiallyRefunded: {
    "id": "intl.paymentStatusMessages.partiallyRefunded",
    defaultMessage: "Częściowo zwrócono środki"
  },
  fullyRefunded: {
    "id": "intl.paymentStatusMessages.fullyRefunded",
    defaultMessage: "Zwrócono środki"
  }
});
export var orderStatusMessages = defineMessages({
  draft: {
    "id": "intl.orderStatusMessages.draft",
    defaultMessage: "Szkic"
  },
  unfulfilled: {
    "id": "intl.orderStatusMessages.unfulfilled",
    defaultMessage: "Niezrealizowane"
  },
  partiallyFulfilled: {
    "id": "intl.orderStatusMessages.partiallyFulfilled",
    defaultMessage: "Częściowo zrealizowane"
  },
  fulfilled: {
    "id": "intl.orderStatusMessages.fulfilled",
    defaultMessage: "Zrealizowane"
  },
  canceled: {
    "id": "intl.orderStatusMessages.canceled",
    defaultMessage: "Anulowane"
  }
});
export function translatePaymentStatus(status, intl) {
  switch (status) {
    case "Not charged":
      return intl.formatMessage(paymentStatusMessages.notCharged);

    case "Partially charged":
      return intl.formatMessage(paymentStatusMessages.partiallyCharged);

    case "Fully charged":
      return intl.formatMessage(paymentStatusMessages.fullyCharged);

    case "Partially refunded":
      return intl.formatMessage(paymentStatusMessages.partiallyRefunded);

    case "Fully refunded":
      return intl.formatMessage(paymentStatusMessages.fullyRefunded);

    default:
      return status;
  }
}
export function translateOrderStatus(status, intl) {
  switch (status) {
    case "Draft":
      return intl.formatMessage(orderStatusMessages.draft);

    case "Unfulfilled":
      return intl.formatMessage(orderStatusMessages.unfulfilled);

    case "Partially fulfilled":
      return intl.formatMessage(orderStatusMessages.partiallyFulfilled);

    case "Fulfilled":
      return intl.formatMessage(orderStatusMessages.fulfilled);

    case "Canceled":
      return intl.formatMessage(orderStatusMessages.canceled);

    default:
      return status;
  }
}