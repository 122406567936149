import "./scss/index.scss";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import ReactSVG from "react-svg";
import { Offline, OfflinePlaceholder, Online, Overlay, PasswordResetRequestForm } from "../..";
import closeImg from "../../../images/x.svg";

var Password = function Password(_ref) {
  var overlay = _ref.overlay;
  return React.createElement(Overlay, {
    testingContext: "passwordOverlay",
    context: overlay
  }, React.createElement("div", {
    className: "password-reset"
  }, React.createElement(Online, null, React.createElement("div", {
    className: "overlay__header"
  }, React.createElement("p", {
    className: "overlay__header-text"
  }, React.createElement(FormattedMessage, {
    id: "components.OverlayManager.Password.index.4195770875",
    defaultMessage: "Zresetuj swoje has\u0142o"
  })), React.createElement(ReactSVG, {
    path: closeImg,
    onClick: overlay.hide,
    className: "overlay__header__close-icon"
  })), React.createElement("div", {
    className: "password-reset__content"
  }, React.createElement(PasswordResetRequestForm, null))), React.createElement(Offline, null, React.createElement(OfflinePlaceholder, null))));
};

export default Password;