import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  position: relative;\n  width: 5rem;\n  height: 1rem;\n  margin: 0 auto;\n\n  span {\n    background-color: ", ";\n    width: 1rem;\n    height: 1rem;\n    border-radius: 1rem;\n    position: absolute;\n\n    &:nth-child(1) {\n      left: 0;\n      animation: ", " 2s infinite;\n      animation-timing-function: linear;\n    }\n\n    &:nth-child(2) {\n      left: 2rem;\n      animation: ", " 2s infinite;\n      animation-timing-function: linear;\n    }\n\n    &:nth-child(3) {\n      right: 0;\n      animation: ", " 2s infinite;\n      animation-timing-function: linear;\n    }\n  }\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  width: 100%;\n  height: ", ";\n  padding: ", " 0;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  0% {\n    left: 4rem;\n  }\n  50% {\n    left: 4rem;\n  }\n  62.5% {\n    left: 2rem;\n  }\n  75% {\n    left: 0;\n  }\n  87.5% {\n    left: 2rem;\n  }\n  100% {\n    left: 4rem;\n  }\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  0% {\n    left: 2rem;\n  }\n  12.5% {\n    left: 2rem;\n  }\n  25% {\n    left: 4rem;\n  }\n  37.5% {\n    left: 2rem;\n  }\n  62.5% {\n    left: 2rem;\n  }\n  75% {\n    left: 0;\n  }\n  87.5% {\n    left: 2rem;\n  }\n  100% {\n    left: 2rem;\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  0% {\n    left: 0;\n  }\n  12.5% {\n    left: 2rem;\n  }\n  25% {\n    left: 4rem;\n  }\n  37.5% {\n    left: 2rem;\n  }\n  50% {\n    left: 0;\n  }\n  100% {\n    left: 0;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
import { keyframes } from "styled-components";
import { getContentWindowHeight } from "@utils/styles";
var firstItem = keyframes(_templateObject());
var secondItem = keyframes(_templateObject2());
var thirdItem = keyframes(_templateObject3());
export var Wrapper = styled.div(_templateObject4(), function (props) {
  return props.fullScreen ? "".concat(getContentWindowHeight(), "px") : "100%";
}, function (props) {
  return props.theme.spacing.spacer;
});
export var Items = styled.div(_templateObject5(), function (props) {
  return props.theme.colors.primary;
}, firstItem, secondItem, thirdItem);