import { Formik } from "formik";
import React from "react";
import * as S from "./styles";

/**
 * PayU payment gateway.
 */
var BankTransferPaymentGateway = function BankTransferPaymentGateway(_ref) {
  var processPayment = _ref.processPayment,
      formRef = _ref.formRef,
      formId = _ref.formId;
  return React.createElement(Formik, {
    initialValues: {},
    onSubmit: function onSubmit(_, _ref2) {
      var setSubmitting = _ref2.setSubmitting;
      processPayment("bank-transfer-token");
      setSubmitting(false);
    }
  }, function (_ref3) {
    var handleSubmit = _ref3.handleSubmit;
    return React.createElement(S.Form, {
      id: formId,
      ref: formRef,
      onSubmit: handleSubmit,
      "data-test": "bankTransferPaymentGatewayForm"
    }, React.createElement(S.HelpText, null, "Szczeg\xF3\u0142y p\u0142atno\u015Bci zostan\u0105 przes\u0142ane w wiadomo\u015Bci e-mail z potwierdzeniem zam\xF3wienia."));
  });
};

export { BankTransferPaymentGateway };