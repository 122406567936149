import _toConsumableArray from "@babel/runtime/helpers/toConsumableArray";
import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _objectSpread from "@babel/runtime/helpers/objectSpread";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import * as React from "react";
import { useIntl } from "react-intl";
import { prodListHeaderCommonMsg } from "@temp/intl";
import { StringParam, useQueryParam } from "use-query-params";
import { useHistory } from "react-router-dom";
import { useQueryParams } from "@temp/hooks";
import { NotFound, OfflinePlaceholder } from "../../components";
import NetworkStatus from "../../components/NetworkStatus";
import { PRODUCTS_PER_PAGE } from "../../core/config";
import { convertSortByFromString, convertToAttributeScalar, getCurrentPathWithExtraPageParams, getGraphqlIdFromDBId } from "../../core/utils";
import Page from "./Page";
import { TypedSearchProductsQuery } from "./queries";
export var FilterQuerySet = {
  encode: function encode(valueObj) {
    var str = [];
    Object.keys(valueObj).forEach(function (value) {
      str.push("".concat(value, "_").concat(valueObj[value].join("_")));
    });
    return str.join(".");
  },
  decode: function decode(strValue) {
    var obj = {};
    var propsWithValues = strValue.split(".").filter(function (n) {
      return n;
    });
    propsWithValues.map(function (value) {
      var propWithValues = value.split("_").filter(function (n) {
        return n;
      });
      obj[propWithValues[0]] = propWithValues.slice(1);
    });
    return obj;
  }
};
export var View = function View(_ref) {
  var match = _ref.match;
  var history = useHistory();
  var queryParams = useQueryParams();
  var currentPage = queryParams.page ? Number(queryParams.page) : 1;

  var _useQueryParam = useQueryParam("sortBy", StringParam),
      _useQueryParam2 = _slicedToArray(_useQueryParam, 2),
      sort = _useQueryParam2[0],
      setSort = _useQueryParam2[1];

  var _useQueryParam3 = useQueryParam("q", StringParam),
      _useQueryParam4 = _slicedToArray(_useQueryParam3, 2),
      search = _useQueryParam4[0],
      _setSearch = _useQueryParam4[1];

  var _useQueryParam5 = useQueryParam("filters", FilterQuerySet),
      _useQueryParam6 = _slicedToArray(_useQueryParam5, 2),
      attributeFilters = _useQueryParam6[0],
      setAttributeFilters = _useQueryParam6[1];

  var intl = useIntl();
  var filters = {
    attributes: attributeFilters,
    offset: (currentPage ? currentPage - 1 : 0) * PRODUCTS_PER_PAGE,
    first: PRODUCTS_PER_PAGE,
    priceGte: null,
    priceLte: null,
    sortBy: sort || null
  };

  var variables = _objectSpread({}, filters, {
    attributes: filters.attributes ? convertToAttributeScalar(filters.attributes) : {},
    id: getGraphqlIdFromDBId(match.params.id, "Category"),
    query: search || null,
    sortBy: convertSortByFromString(filters.sortBy)
  });

  var sortOptions = [{
    label: intl.formatMessage(prodListHeaderCommonMsg.sortOptionsClear),
    value: null
  }, {
    label: intl.formatMessage(prodListHeaderCommonMsg.sortOptionsPrice),
    value: "price"
  }, {
    label: intl.formatMessage(prodListHeaderCommonMsg.sortOptionsPriceDsc),
    value: "-price"
  }, {
    label: intl.formatMessage(prodListHeaderCommonMsg.sortOptionsName),
    value: "name"
  }, {
    label: intl.formatMessage(prodListHeaderCommonMsg.sortOptionsNameDsc),
    value: "-name"
  }, {
    label: intl.formatMessage(prodListHeaderCommonMsg.sortOptionsUpdatedAt),
    value: "updated_at"
  }, {
    label: intl.formatMessage(prodListHeaderCommonMsg.sortOptionsUpdatedAtDsc),
    value: "-updated_at"
  }];

  var clearFilters = function clearFilters() {
    setAttributeFilters({});
    history.push(getCurrentPathWithExtraPageParams("1"));
  };

  var onFiltersChange = function onFiltersChange(name, value) {
    if (attributeFilters && attributeFilters.hasOwnProperty(name)) {
      if (attributeFilters[name].includes(value)) {
        if (filters.attributes["".concat(name)].length === 1) {
          var att = _objectSpread({}, attributeFilters);

          delete att["".concat(name)];
          setAttributeFilters(_objectSpread({}, att));
        } else {
          setAttributeFilters(_objectSpread({}, attributeFilters, _defineProperty({}, "".concat(name), attributeFilters["".concat(name)].filter(function (item) {
            return item !== value;
          }))));
        }
      } else {
        setAttributeFilters(_objectSpread({}, attributeFilters, _defineProperty({}, "".concat(name), [].concat(_toConsumableArray(attributeFilters["".concat(name)]), [value]))));
      }
    } else {
      setAttributeFilters(_objectSpread({}, attributeFilters, _defineProperty({}, "".concat(name), [value])));
    }

    history.push(getCurrentPathWithExtraPageParams("1"));
  };

  return React.createElement(NetworkStatus, null, function (isOnline) {
    return React.createElement(TypedSearchProductsQuery, {
      variables: variables,
      errorPolicy: "all",
      loaderFull: true
    }, function (_ref2) {
      var _data$attributes, _data$products;

      var loading = _ref2.loading,
          data = _ref2.data,
          loadMore = _ref2.loadMore;
      var canDisplayFilters = !!(data === null || data === void 0 ? void 0 : (_data$attributes = data.attributes) === null || _data$attributes === void 0 ? void 0 : _data$attributes.edges) && !!(data === null || data === void 0 ? void 0 : (_data$products = data.products) === null || _data$products === void 0 ? void 0 : _data$products.edges);

      if (canDisplayFilters) {
        return React.createElement(Page, {
          clearFilters: clearFilters,
          attributes: data.attributes.edges.map(function (edge) {
            return edge.node;
          }),
          displayLoader: loading,
          sortOptions: sortOptions,
          setSearch: function setSearch(value) {
            _setSearch(value);

            history.push(getCurrentPathWithExtraPageParams("1"));
          },
          search: search,
          activeSortOption: filters.sortBy,
          filters: filters,
          products: data.products,
          onAttributeFiltersChange: onFiltersChange,
          activeFilters: filters.attributes ? Object.keys(filters.attributes).length : 0,
          onOrder: function onOrder(value) {
            setSort(value.value);
          }
        });
      }

      if (data && data.products === null) {
        return React.createElement(NotFound, null);
      }

      if (!isOnline) {
        return React.createElement(OfflinePlaceholder, null);
      }
    });
  });
};
export default View;