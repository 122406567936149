import * as React from "react";
import { RichTextContent } from "@components/atoms";
import { Breadcrumbs } from "../../components";
export var Page = function Page(_ref) {
  var breadcrumbs = _ref.breadcrumbs,
      headerImage = _ref.headerImage,
      navigation = _ref.navigation,
      page = _ref.page;
  return React.createElement("div", {
    className: "article-page"
  }, React.createElement("div", {
    className: "article-page__header",
    style: headerImage ? {
      backgroundImage: "url(".concat(headerImage, ")")
    } : null
  }, React.createElement("span", {
    className: "article-page__header__title"
  }, React.createElement("h1", null, page.title))), React.createElement("div", {
    className: "container"
  }, React.createElement(Breadcrumbs, {
    breadcrumbs: breadcrumbs
  }), React.createElement("div", {
    className: "article-page__container"
  }, React.createElement("div", {
    className: "article-page__content"
  }, React.createElement(RichTextContent, {
    descriptionHtml: page.contentHtml
  })))));
};
export default Page;