import _extends from "@babel/runtime/helpers/extends";
import "./scss/index.scss";
import * as React from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { commonMessages } from "@temp/intl";
import { Button, Form, TextField } from "..";
import { TypedPasswordResetRequestMutation } from "./queries";
import { passwordResetUrl } from "../../app/routes";

var PasswordResetRequestForm = function PasswordResetRequestForm() {
  var intl = useIntl();

  var disableSubmit = function disableSubmit(loading, data) {
    return loading || (data === null || data === void 0 ? void 0 : data.requestPasswordReset.errors.length) === 0;
  };

  var buttonMessage = function buttonMessage(loading, data) {
    if (loading) {
      return intl.formatMessage(commonMessages.loading);
    }

    if ((data === null || data === void 0 ? void 0 : data.requestPasswordReset.errors.length) === 0) {
      return intl.formatMessage({
        defaultMessage: "Sprawdź swoją skrzynkę odbiorczą",
        "id": "components.PasswordResetRequestForm.index.3464176124"
      });
    }

    return intl.formatMessage({
      defaultMessage: "Zresetuj hasło",
      "id": "components.PasswordResetRequestForm.index.923388055"
    });
  };

  return React.createElement("div", {
    className: "password-reset-form"
  }, React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "components.PasswordResetRequestForm.index.4169877350",
    defaultMessage: "Podaj nam sw\xF3j adres e-mail, aby\u015Bmy mogli udost\u0119pni\u0107 Ci link do zresetowania has\u0142a"
  })), React.createElement(TypedPasswordResetRequestMutation, null, function (passwordReset, _ref) {
    var loading = _ref.loading,
        data = _ref.data;
    return React.createElement(Form, {
      errors: (data === null || data === void 0 ? void 0 : data.requestPasswordReset.errors) || [],
      onSubmit: function onSubmit(event, _ref2) {
        var email = _ref2.email;
        event.preventDefault();
        passwordReset({
          variables: {
            email: email,
            redirectUrl: "".concat(window.location.origin).concat(passwordResetUrl)
          }
        });
      }
    }, React.createElement(TextField, {
      name: "email",
      autoComplete: "email",
      label: intl.formatMessage(commonMessages.eMail),
      type: "email",
      required: true
    }), React.createElement("div", {
      className: "password-reset-form__button"
    }, React.createElement(Button, _extends({
      testingContext: "submit",
      type: "submit"
    }, disableSubmit(loading, data) && {
      disabled: true
    }), buttonMessage(loading, data))));
  }));
};

export default PasswordResetRequestForm;