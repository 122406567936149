import * as React from "react";
import { FormattedMessage } from "react-intl";
import { ProductList } from "@components/organisms";

var OtherProducts = function OtherProducts(_ref) {
  var products = _ref.products;
  return React.createElement("div", {
    className: "product-page__other-products"
  }, React.createElement("div", {
    className: "container"
  }, React.createElement("h4", {
    className: "product-page__other-products__title"
  }, React.createElement(FormattedMessage, {
    id: "views.Product.Other.3836561228",
    defaultMessage: "Inne produkty z tej kategorii"
  })), React.createElement(ProductList, {
    products: products.map(function (_ref2) {
      var node = _ref2.node;
      return node;
    })
  })));
};

export default OtherProducts;