import React from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "@components/atoms";
import { Container } from "@components/templates";
import { checkoutMessages } from "@temp/intl";
import * as S from "./styles";

/**
 * Thank you page after completing the checkout.
 */
var ThankYou = function ThankYou(_ref) {
  var orderNumber = _ref.orderNumber,
      continueShopping = _ref.continueShopping,
      orderDetails = _ref.orderDetails;
  return React.createElement(Container, {
    "data-test": "thankYouView"
  }, React.createElement(S.Wrapper, null, React.createElement(S.ThankYouHeader, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.ThankYou.ThankYou.181871912",
    defaultMessage: "Dzi\u0119kujemy"
  }), React.createElement("br", null), React.createElement(FormattedMessage, {
    id: "@next.components.organisms.ThankYou.ThankYou.1564919753",
    defaultMessage: "za Twoje zam\xF3wienie"
  })), React.createElement(S.Paragraph, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.ThankYou.ThankYou.961698950",
    defaultMessage: "Twoje zam\xF3wienie ma numer"
  }), " ", React.createElement("span", null, orderNumber)), React.createElement(S.Paragraph, null, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.ThankYou.ThankYou.986071137",
    defaultMessage: "Wys\u0142ali\u015Bmy Ci wiadomo\u015B\u0107 z potwierdzeniem zam\xF3wienia. Powiadomimy Ci\u0119 kiedy zostanie wys\u0142ane."
  })), React.createElement(S.Buttons, null, React.createElement(Button, {
    testingContext: "continueShoppingButton",
    onClick: continueShopping,
    fullWidth: true
  }, React.createElement(FormattedMessage, checkoutMessages.continueShopping)), React.createElement(Button, {
    testingContext: "gotoOrderDetailsButton",
    onClick: orderDetails,
    fullWidth: true
  }, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.ThankYou.ThankYou.953657426",
    defaultMessage: "SZCZEG\xD3\u0141Y ZAM\xD3WIENIA"
  })))));
};

export { ThankYou };