import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 0;\n  margin: 0;\n  cursor: pointer;\n\n  width: ", ";\n  height: ", ";\n  // background-color: ", ";\n\n  border-width: 0;\n\n  transition: 0.3s;\n\n  svg {\n    display: block;\n    path {\n      transition: 0.3s;\n      // fill: ", ";\n    }\n  }\n\n  :hover {\n    background-color: ", ";\n    svg {\n      path {\n        fill: ", ";\n      }\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject(), function (props) {
  return "".concat(props.theme.iconButton.size, "px");
}, function (props) {
  return "".concat(props.theme.iconButton.size, "px");
}, function (props) {
  return props.theme.iconButton.backgroundColor;
}, function (props) {
  return props.theme.colors.white;
}, function (props) {
  return props.theme.iconButton.hoverBackgroundColor;
}, function (props) {
  return props.theme.iconButton.hoverForegroundColor;
});