import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n    grid-template-columns: 1fr;\n    grid-row-gap: 20px;\n    width: 100%;\n    margin-top: 20px;\n  "]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  width: 50%;\n  margin-top: 40px;\n  display: grid;\n  grid-template-columns: 1fr 1fr;\n  grid-column-gap: 30px;\n  button {\n    padding-left: 0;\n    padding-right: 0;\n  }\n\n  ", "\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  font-size: ", ";\n  margin: 0;\n  line-height: 170%;\n\n  span {\n    font-weight: ", ";\n  }\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n    font-size: ", ";\n  "]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  font-size: ", ";\n  margin: 0;\n  line-height: 110%;\n  span {\n    font-weight: ", ";\n  }\n  padding-bottom: 40px;\n  border-bottom: 1px solid\n    ", ";\n  margin-bottom: 40px;\n\n  ", "\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n    margin: 40px 0;\n  "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  margin: 80px 0;\n\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { media, styled } from "@styles";
export var Wrapper = styled.div(_templateObject(), function (props) {
  return props.theme.colors.light;
}, media.smallScreen(_templateObject2()));
export var ThankYouHeader = styled.p(_templateObject3(), function (props) {
  return props.theme.typography.ultraBigFontSize;
}, function (props) {
  return props.theme.typography.boldFontWeight;
}, function (props) {
  return props.theme.colors.baseFontColorTransparent;
}, media.smallScreen(_templateObject4(), function (props) {
  return props.theme.typography.h1FontSize;
}));
export var Paragraph = styled.p(_templateObject5(), function (props) {
  return props.theme.typography.h4FontSize;
}, function (props) {
  return props.theme.typography.boldFontWeight;
});
export var Buttons = styled.div(_templateObject6(), media.smallScreen(_templateObject7()));