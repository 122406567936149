import React from "react";
import { FormattedMessage } from "react-intl";
import * as S from "./styles";
import { Container } from "../Container";
/**
 * Template for empty cart page.
 */

var CartEmpty = function CartEmpty(_ref) {
  var button = _ref.button;
  return React.createElement(Container, null, React.createElement(S.Wrapper, null, React.createElement(S.TitleFirstLine, null, React.createElement(FormattedMessage, {
    id: "@next.components.templates.CartEmpty.CartEmpty.3476713654",
    defaultMessage: "Tw\xF3j koszyk"
  })), React.createElement(S.TitleFirstLine, null, React.createElement(FormattedMessage, {
    id: "@next.components.templates.CartEmpty.CartEmpty.562500253",
    defaultMessage: "wygl\u0105da na pusty"
  })), React.createElement(S.HR, null), React.createElement(S.Subtitle, null, React.createElement(FormattedMessage, {
    id: "@next.components.templates.CartEmpty.CartEmpty.3863906436",
    defaultMessage: "Sprawd\u017A nasz\u0105 ofert\u0119, na pewno znajdziesz co\u015B dla siebie"
  })), React.createElement(S.ContinueButton, null, button)));
};

export { CartEmpty };