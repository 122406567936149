import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  cursor: pointer;\n  justify-content: flex-start;\n  align-items: center;\n  padding-right: 1.25rem;\n  input[type=\"checkbox\"] {\n    display: none;\n    position: relative;\n    right: -999em;\n  }\n  div {\n    display: flex;\n    justify-content: center;\n    align-items: center;\n    height: 18px;\n    width: 18px;\n\n    span {\n      border: 1px solid\n        ", ";\n      width: 14px;\n      height: 14px;\n      display: inline-block;\n    }\n\n    ", ":hover & {\n      border: 1px solid;\n      border-color: ", ";\n      background-color: ", ";\n    }\n  }\n\n  input:checked + div {\n    span {\n      background-clip: content-box;\n      padding: 2px;\n      background-color: ", ";\n    }\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  margin-bottom: 1.25rem;\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  cursor: pointer;\n  position: relative;\n  margin-left: -4px;\n  color: ", ";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Checkbox = styled.div(_templateObject(), function (props) {
  return props.formBackgroundColor === "black" ? props.theme.colors.primary : props.theme.colors.darkFont;
});
export var Label = styled.label(_templateObject2(), function (props) {
  return props.formBackgroundColor === "black" ? props.theme.colors.primary : props.theme.colors.darkFont;
}, Checkbox, function (props) {
  return props.theme.colors.darkFont;
}, function (props) {
  return props.theme.colors.primary;
}, function (props) {
  return props.formBackgroundColor === "black" ? props.theme.colors.primary : props.theme.colors.darkFont;
});