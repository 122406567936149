import React from "react";
import { FormattedMessage } from "react-intl";
import { RichTextContent } from "@components/atoms";
import * as S from "./styles";
export var ProductDescription = function ProductDescription(_ref) {
  var _ref$description = _ref.description,
      description = _ref$description === void 0 ? "" : _ref$description,
      _ref$descriptionHtml = _ref.descriptionHtml,
      descriptionHtml = _ref$descriptionHtml === void 0 ? "" : _ref$descriptionHtml,
      attributes = _ref.attributes;
  return React.createElement(S.Wrapper, null, React.createElement(S.Tabs, null, React.createElement(S.TabTitle, {
    active: !!1,
    onMouseEnter: function onMouseEnter() {},
    onClick: function onClick() {}
  }, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.ProductDescription.ProductDescription.1563478539",
    defaultMessage: "OPIS"
  }))), attributes && React.createElement(S.AttributeList, null, attributes.map(function (attribute, index) {
    return React.createElement("li", {
      key: index
    }, React.createElement(S.AttributeName, null, attribute.attribute.name, ": "), " ", attribute.values.map(function (value) {
      return value.name;
    }).join(", "));
  })), descriptionHtml || descriptionHtml === "" ? React.createElement(RichTextContent, {
    descriptionHtml: descriptionHtml
  }) : React.createElement("p", null, description));
};