import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  font-weight: ", ";\n  padding: 0 0 1.6rem 0;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  border-bottom: 1px solid\n    ", ";\n  margin: 30px 0;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral([""]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject());
export var Divider = styled.div(_templateObject2(), function (props) {
  return props.theme.colors.baseFontColorTransparent;
});
export var Title = styled.h3(_templateObject3(), function (props) {
  return props.theme.colors.lightFont;
}, function (props) {
  return props.theme.typography.boldFontWeight;
});