import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject10() {
  var data = _taggedTemplateLiteral(["\n     width: 50%;\n  "]);

  _templateObject10 = function _templateObject10() {
    return data;
  };

  return data;
}

function _templateObject9() {
  var data = _taggedTemplateLiteral(["\n  width: 25%;\n  ", "\n"]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  width: 10%;\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  width: 25%;\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  width: 25%;\n  display: flex;\n  flex-wrap: nowrap;\n  justify-content: center;\n\n  img {\n    max-width: 50px;\n    height: auto;\n  }\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n     width: 50%;\n  "]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  width: 15%;\n  ", "\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  cursor: default;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  display: flex;\n  width: 100%;\n  flex-direction: row;\n  text-align: center;\n  justify-content: center;\n  align-items: center;\n  height: 5rem;\n  cursor: pointer;\n\n  border-bottom: 1px solid ", ";\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral([""]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { media, styled } from "@styles";
export var Wrapper = styled.div(_templateObject());
export var Row = styled.div(_templateObject2(), function (props) {
  return props.theme.colors.light;
}, function (props) {
  return props.theme.colors.lightFont;
});
export var HeaderRow = styled(Row)(_templateObject3(), function (props) {
  return props.theme.colors.lightFont;
});
export var IndexNumber = styled.div(_templateObject4(), media.smallScreen(_templateObject5()));
export var ProductsOrdered = styled.div(_templateObject6());
export var DateOfOrder = styled.div(_templateObject7());
export var Value = styled.div(_templateObject8());
export var Status = styled.div(_templateObject9(), media.smallScreen(_templateObject10()));