import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  display: block;\n  margin: 30px 0 0 7px;\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  font-size: ", ";\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  display: block;\n  color: ", ";\n  // background-color: ", ";\n  padding: 20px;\n  ", "\n  cursor: pointer;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  font-weight: ", ";\n  padding: 0 0 1.6rem 0;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  display: grid;\n  grid-gap: 20px;\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var ShippingMethodForm = styled.form(_templateObject());
export var Title = styled.h3(_templateObject2(), function (props) {
  return props.theme.colors.lightFont;
}, function (props) {
  return props.theme.typography.boldFontWeight;
});
export var Tile = styled.label(_templateObject3(), function (props) {
  return props.theme.colors.light;
}, function (props) {
  return props.theme.colors.light;
}, function (props) {
  return props.checked && "border: 2px solid ".concat(props.theme.colors.primary, ";");
});
export var TileTitle = styled.span(_templateObject4(), function (props) {
  return props.theme.typography.smallFontSize;
});
export var Price = styled.span(_templateObject5(), function (props) {
  return props.theme.colors.lightFont;
});
export var PayOnDelivery = styled.span(_templateObject6());