import _objectSpread from "@babel/runtime/helpers/objectSpread";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Checkbox, IconButton } from "@components/atoms";
import { AttributeValuesChecklist } from "@components/molecules";
import { useHandlerWhenClickedOutside } from "@hooks";
import { commonMessages } from "@temp/intl";
import { IN_STOCK_ONLY_FILTER } from "@components/organisms/FilterSidebar/constants";
import { Overlay } from "..";
import * as S from "./styles";

var checkIfAttributeIsChecked = function checkIfAttributeIsChecked(filters, value, slug) {
  if (filters.attributes && filters.attributes.hasOwnProperty(slug)) {
    return !!filters.attributes[slug].find(function (filter) {
      return filter === value.slug;
    });
  }

  return false;
};

export var FilterSidebar = function FilterSidebar(_ref) {
  var hide = _ref.hide,
      filters = _ref.filters,
      show = _ref.show,
      attributes = _ref.attributes,
      target = _ref.target,
      onAttributeFiltersChange = _ref.onAttributeFiltersChange;

  var _useHandlerWhenClicke = useHandlerWhenClickedOutside(function () {
    hide();
  }),
      setElementRef = _useHandlerWhenClicke.setElementRef;

  return React.createElement(Overlay, {
    duration: 0,
    position: "left",
    show: show,
    hide: hide,
    transparent: true,
    target: target
  }, React.createElement(S.Wrapper, {
    ref: setElementRef(),
    "data-test": "filterSidebar"
  }, React.createElement(S.Header, null, React.createElement("span", null, React.createElement(FormattedMessage, commonMessages.filterHeader)), React.createElement(IconButton, {
    testingContext: "hideFilters",
    onClick: hide,
    name: "x",
    size: 18,
    color: "000"
  })), React.createElement(S.Filter, null, React.createElement(Checkbox, {
    name: IN_STOCK_ONLY_FILTER.name,
    checked: checkIfAttributeIsChecked(filters, IN_STOCK_ONLY_FILTER, IN_STOCK_ONLY_FILTER.slug),
    onChange: function onChange() {
      return onAttributeFiltersChange(IN_STOCK_ONLY_FILTER.slug, IN_STOCK_ONLY_FILTER.slug);
    },
    formBackgroundColor: "white"
  }, IN_STOCK_ONLY_FILTER.name)), attributes.map(function (_ref2) {
    var id = _ref2.id,
        name = _ref2.name,
        slug = _ref2.slug,
        values = _ref2.values;
    return React.createElement(AttributeValuesChecklist, {
      key: id,
      title: name,
      name: slug,
      values: values.sort(function (a, b) {
        return a.name > b.name ? 1 : -1;
      }).map(function (value) {
        return _objectSpread({}, value, {
          selected: checkIfAttributeIsChecked(filters, value, slug)
        });
      }),
      formBackgroundColor: "white",
      valuesShowLimit: true,
      onValueClick: function onValueClick(value) {
        return onAttributeFiltersChange(slug, value.slug);
      }
    });
  })));
};