import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  margin-top: 30px;\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  line-height: 1.6;\n  font-size: ", ";\n  color: ", ";\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  padding: 0.6rem 0 1.4rem 0;\n  font-size: ", ";\n  color: ", ";\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  font-weight: ", ";\n  padding: 0 0 1.6rem 0;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  width: 100%;\n  border-bottom: 1px solid\n    ", ";\n  margin: 0 0 20px 0;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n    grid-template-columns: repeat(1, 1fr);\n  "]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  display: grid;\n  grid-gap: 30px;\n  grid-template-columns: repeat(2, 1fr);\n\n  ", "\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral([""]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { media, styled } from "@styles";
export var Wrapper = styled.div(_templateObject());
export var Grid = styled.div(_templateObject2(), media.smallScreen(_templateObject3()));
export var Divider = styled.div(_templateObject4(), function (props) {
  return props.theme.colors.baseFontColorTransparent;
});
export var Title = styled.h3(_templateObject5(), function (props) {
  return props.theme.colors.lightFont;
}, function (props) {
  return props.theme.typography.boldFontWeight;
});
export var SubTitle = styled.h4(_templateObject6(), function (props) {
  return props.theme.typography.baseFontSize;
}, function (props) {
  return props.theme.colors.lightFont;
});
export var TextSummary = styled.p(_templateObject7(), function (props) {
  return props.theme.typography.h4FontSize;
}, function (props) {
  return props.theme.colors.light;
});
export var ErrorMessages = styled.div(_templateObject8());