import { Formik } from "formik";
import React from "react";
import { FormattedMessage } from "react-intl";
import { ErrorMessage, Radio, Checkbox } from "@components/atoms";
import { Money } from "@components/containers";
import { checkoutMessages } from "@temp/intl";
import InPostShipmentGateway from "src/components/InPostShippingGateway";
import { PROVIDERS } from "@temp/core/config";
import * as S from "./styles";
export var payOnDeliveryPostfix = "(Płatność przy odbiorze)";
/**
 * Shipping method selector used in checkout.
 */

var CheckoutShipping = function CheckoutShipping(_ref) {
  var _ref2;

  var shippingMethods = _ref.shippingMethods,
      paymentGateways = _ref.paymentGateways,
      selectedShippingMethodId = _ref.selectedShippingMethodId,
      selectShippingMethod = _ref.selectShippingMethod,
      errors = _ref.errors,
      formId = _ref.formId,
      formRef = _ref.formRef,
      checkoutToken = _ref.checkoutToken,
      selectedPaymentGateway = _ref.selectedPaymentGateway,
      selectPaymentGateway = _ref.selectPaymentGateway;
  var cashPaymentGateway = paymentGateways === null || paymentGateways === void 0 ? void 0 : paymentGateways.find(function (paymentGateway) {
    return paymentGateway.name === PROVIDERS.CASH.label;
  });
  var payOnDeliveryPossible = !!cashPaymentGateway;
  return React.createElement("section", null, React.createElement(S.Title, {
    "data-test": "checkoutPageSubtitle"
  }, React.createElement(FormattedMessage, checkoutMessages.shippingMethod)), React.createElement(Formik, {
    initialValues: {
      shippingMethod: selectedShippingMethodId,
      payOnDelivery: (_ref2 = selectedPaymentGateway === (cashPaymentGateway === null || cashPaymentGateway === void 0 ? void 0 : cashPaymentGateway.id)) !== null && _ref2 !== void 0 ? _ref2 : false
    },
    enableReinitialize: true,
    onSubmit: function onSubmit(values, _ref3) {
      var setSubmitting = _ref3.setSubmitting;

      if (selectShippingMethod && values.shippingMethod) {
        selectShippingMethod(values.shippingMethod);
        if (payOnDeliveryPossible && values.payOnDelivery) selectPaymentGateway(cashPaymentGateway.id);
      }

      setSubmitting(false);
    }
  }, function (_ref4) {
    var handleChange = _ref4.handleChange,
        handleSubmit = _ref4.handleSubmit,
        handleBlur = _ref4.handleBlur,
        values = _ref4.values,
        setFieldValue = _ref4.setFieldValue,
        setFieldTouched = _ref4.setFieldTouched;
    return React.createElement(S.ShippingMethodForm, {
      id: formId,
      ref: formRef,
      onSubmit: handleSubmit
    }, shippingMethods.map(function (_ref5, index) {
      var id = _ref5.id,
          name = _ref5.name,
          price = _ref5.price;

      if (name.toLocaleLowerCase().endsWith(payOnDeliveryPostfix.toLocaleLowerCase())) {
        return;
      }

      var payOnDeliveryOption = shippingMethods.find(function (shippingMethod) {
        return shippingMethod.name.toLocaleLowerCase() === "".concat(name, " ").concat(payOnDeliveryPostfix).toLocaleLowerCase();
      });
      var checked = !!values.shippingMethod && values.shippingMethod === id || !!values.shippingMethod && !!payOnDeliveryOption && values.shippingMethod === payOnDeliveryOption.id;

      if (checked && values.payOnDelivery && payOnDeliveryOption && values.shippingMethod !== payOnDeliveryOption.id) {
        setFieldValue("shippingMethod", payOnDeliveryOption.id);
      }

      return React.createElement(S.Tile, {
        checked: checked,
        key: id,
        "data-test": "shippingMethodTile",
        "data-test-id": id
      }, React.createElement(Radio, {
        name: "shippingMethod",
        value: id,
        checked: checked,
        customLabel: true,
        onChange: function onChange() {
          return setFieldValue("shippingMethod", id);
        }
      }, React.createElement(S.TileTitle, null, React.createElement("span", {
        "data-test": "checkoutShippingMethodOptionName"
      }, name), React.createElement(S.Price, null, " ", "| +", React.createElement(Money, {
        "data-test": "checkoutShippingMethodOptionPrice",
        money: price
      })))), payOnDeliveryPossible && checked && React.createElement(S.PayOnDelivery, null, React.createElement(Checkbox, {
        name: "payOnDelivery",
        checked: values.payOnDelivery,
        onChange: function onChange() {
          if (payOnDeliveryOption) {
            setFieldValue("shippingMethod", !values.payOnDelivery ? payOnDeliveryOption.id : id);
          }

          setFieldValue("payOnDelivery", !values.payOnDelivery);
        }
      }, "P\u0142atno\u015B\u0107 przy odbiorze", !!payOnDeliveryOption && React.createElement(React.Fragment, null, " ", "| +", " ", React.createElement(Money, {
        "data-test": "checkoutShippingMethodOptionPrice",
        money: {
          // @ts-ignore
          currency: payOnDeliveryOption.price.currency,
          amount: // @ts-ignore
          payOnDeliveryOption.price.amount - price.amount
        }
      })))), checked && checkoutToken && name.toLowerCase().includes("inpost") && React.createElement(InPostShipmentGateway, {
        checkoutToken: checkoutToken
      }));
    }), React.createElement(ErrorMessage, {
      errors: errors
    }));
  }));
};

export { CheckoutShipping };