import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { useMemo } from "react";
import { useLocation } from "react-router-dom";
export function useQueryParams() {
  var location = useLocation();
  return useMemo(function () {
    var urlParams = new URLSearchParams(location.search);
    var result = {}; // @ts-ignore
    // eslint-disable-next-line no-restricted-syntax

    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = urlParams[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var _step$value = _slicedToArray(_step.value, 2),
            param = _step$value[0],
            value = _step$value[1];

        result[param] = value;
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return != null) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }

    return result;
  }, [location.search]);
}