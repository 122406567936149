import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  display: block;\n  // background-color: ", ";\n  padding: 20px;\n  ", "\n  font-size: ", ";\n  cursor: pointer;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n    padding: 30px 20px;\n  "]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  // background-color: ", ";\n  padding: 30px;\n\n  ", "\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  font-weight: ", ";\n  padding: 0 0 1.6rem 0;\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral([""]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { media, styled } from "@styles";
export var Wrapper = styled.div(_templateObject());
export var Title = styled.h3(_templateObject2(), function (props) {
  return props.theme.colors.lightFont;
}, function (props) {
  return props.theme.typography.boldFontWeight;
});
export var DiscountField = styled.div(_templateObject3(), function (props) {
  return props.theme.colors.light;
}, media.smallScreen(_templateObject4()));
export var Tile = styled.label(_templateObject5(), function (props) {
  return props.theme.colors.light;
}, function (props) {
  return props.checked && "border: 2px solid ".concat(props.theme.colors.primary, ";");
}, function (props) {
  return props.theme.typography.smallFontSize;
});