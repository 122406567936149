import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import _objectSpread from "@babel/runtime/helpers/objectSpread";
import React, { memo } from "react";
import { Icon } from "@components/atoms";
import { usePagination } from "@material-ui/lab";
import { Link, Nav, PaginationList, Button, ListButton } from "@components/organisms/Pagination/styles";
import { getCurrentPathWithExtraPageParams } from "@temp/core/utils";
export var Pagination = memo(function (props) {
  var pageNum = props.page;

  var _usePagination = usePagination(_objectSpread({}, props)),
      items = _usePagination.items;

  return React.createElement(Nav, null, React.createElement(PaginationList, null, items.map(function (_ref, index) {
    var page = _ref.page,
        type = _ref.type,
        selected = _ref.selected,
        item = _objectWithoutProperties(_ref, ["page", "type", "selected"]);

    var child = ["start-ellipsis", "end-ellipsis"].includes(type) ? React.createElement(ListButton, {
      disabled: true
    }, "...") : type === "page" ? React.createElement(Link, {
      href: pageNum ? getCurrentPathWithExtraPageParams(page.toString()) : "#"
    }, React.createElement(ListButton, _extends({
      selected: selected
    }, item), page)) : React.createElement(Button, item, type === "previous" ? React.createElement(Icon, {
      size: 32,
      name: "arrow_double_left"
    }) : React.createElement(Icon, {
      size: 32,
      name: "arrow_double_right"
    }));
    return React.createElement("li", {
      key: index
    }, child);
  })));
});