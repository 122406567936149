import _objectSpread from "@babel/runtime/helpers/objectSpread";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Checkbox } from "@components/atoms";
import { checkoutMessages } from "@temp/intl";
import { filterNotEmptyArrayItems } from "@utils/misc";
import { AddressForm } from "../AddressForm";
import { AddressGridSelector } from "../AddressGridSelector";
import * as S from "./styles";

/**
 * Address form used in checkout.
 */
var CheckoutAddress = function CheckoutAddress(_ref) {
  var checkoutShippingAddress = _ref.checkoutShippingAddress,
      checkoutBillingAddress = _ref.checkoutBillingAddress,
      _ref$billingAsShippin = _ref.billingAsShippingAddress,
      billingAsShippingAddress = _ref$billingAsShippin === void 0 ? false : _ref$billingAsShippin,
      email = _ref.email,
      selectedUserShippingAddressId = _ref.selectedUserShippingAddressId,
      selectedUserBillingAddressId = _ref.selectedUserBillingAddressId,
      userAddresses = _ref.userAddresses,
      countries = _ref.countries,
      userId = _ref.userId,
      shippingFormId = _ref.shippingFormId,
      shippingFormRef = _ref.shippingFormRef,
      billingFormId = _ref.billingFormId,
      billingFormRef = _ref.billingFormRef,
      shippingAddressRequired = _ref.shippingAddressRequired,
      setShippingAddress = _ref.setShippingAddress,
      setBillingAddress = _ref.setBillingAddress,
      setBillingAsShippingAddress = _ref.setBillingAsShippingAddress,
      shippingErrors = _ref.shippingErrors,
      billingErrors = _ref.billingErrors,
      newAddressFormId = _ref.newAddressFormId;
  return React.createElement(S.Wrapper, null, shippingAddressRequired && React.createElement(React.Fragment, null, React.createElement("section", null, React.createElement(S.Title, {
    "data-test": "checkoutPageSubtitle"
  }, React.createElement(FormattedMessage, checkoutMessages.shippingAddress)), userAddresses ? React.createElement(AddressGridSelector, {
    testingContext: "shipping",
    formId: shippingFormId,
    formRef: shippingFormRef,
    addresses: userAddresses,
    selectedAddressId: selectedUserShippingAddressId,
    countriesOptions: countries === null || countries === void 0 ? void 0 : countries.filter(filterNotEmptyArrayItems),
    userId: userId,
    errors: shippingErrors,
    onSelect: function onSelect(address, id) {
      return setShippingAddress(address, undefined, id);
    },
    newAddressFormId: newAddressFormId
  }) : React.createElement(AddressForm, {
    testingContext: "shippingAddressForm",
    formId: shippingFormId,
    formRef: shippingFormRef,
    countriesOptions: countries === null || countries === void 0 ? void 0 : countries.filter(filterNotEmptyArrayItems),
    address: _objectSpread({}, checkoutShippingAddress, {
      email: email
    }),
    handleSubmit: function handleSubmit(address) {
      return setShippingAddress(address, address === null || address === void 0 ? void 0 : address.email);
    },
    includeEmail: true,
    errors: shippingErrors
  })), React.createElement(S.Divider, null)), React.createElement("section", null, React.createElement(S.Title, {
    "data-test": "checkoutPageSubtitle"
  }, React.createElement(FormattedMessage, checkoutMessages.billingAddress)), shippingAddressRequired && React.createElement(Checkbox, {
    "data-test": "checkoutAddressBillingAsShippingCheckbox",
    name: "billing-same-as-shipping",
    checked: billingAsShippingAddress,
    onChange: function onChange() {
      return setBillingAsShippingAddress(!billingAsShippingAddress);
    }
  }, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.CheckoutAddress.CheckoutAddress.3989477439",
    defaultMessage: "Taki sam jak adres dostawy"
  })), !billingAsShippingAddress && React.createElement(React.Fragment, null, shippingAddressRequired && React.createElement(S.Divider, null), userAddresses ? React.createElement(AddressGridSelector, {
    testingContext: "billing",
    formId: billingFormId,
    formRef: billingFormRef,
    addresses: userAddresses,
    selectedAddressId: selectedUserBillingAddressId,
    countriesOptions: countries === null || countries === void 0 ? void 0 : countries.filter(filterNotEmptyArrayItems),
    userId: userId,
    errors: billingErrors,
    onSelect: function onSelect(address, id) {
      return setBillingAddress(address, undefined, id);
    },
    newAddressFormId: newAddressFormId
  }) : React.createElement(AddressForm, {
    testingContext: "billingAddressForm",
    formId: billingFormId,
    formRef: billingFormRef,
    countriesOptions: countries === null || countries === void 0 ? void 0 : countries.filter(filterNotEmptyArrayItems),
    address: checkoutBillingAddress || undefined,
    handleSubmit: function handleSubmit(address) {
      return setBillingAddress(address, address === null || address === void 0 ? void 0 : address.email);
    },
    includeEmail: !shippingAddressRequired,
    errors: billingErrors
  }))));
};

export { CheckoutAddress };