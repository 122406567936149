// colors
export var autofillColor = "rgb(250, 255, 189)";
export var autofillColorSelected = "rgb(232, 240, 254)";
export var baseFontColor = "#000000";
export var baseFontColorSemiTransparent = "rgba(255, 255, 255, .5)";
export var baseFontColorTransparent = "rgba(0, 0, 0, .1)";
export var black = "#000000";
export var blue = "rgb(33,18,94)";
export var blueDark = "#190c4e";
export var blueLight = "#513CA3";
export var blueOverlay = "rgba(33,18,94,0.1)";
export var blueOverlayDark = "rgba(33,18,94,0.2)";
export var gray = "#7d7d7d";
export var grayMedium = "#c4c4c4";
export var grayDark = "#323232";
export var grayLight = "#f1f5f5";
export var green = "#3ed256";
export var overlayColor = "rgba(199, 207, 207, 0.8)";
export var rose = "#ff285b";
export var turquoise = "#13bebb";
export var turquoiseDark = "#06a09e";
export var turquoiseLight = "rgba(6, 132, 123, 0.25)";
export var turquoiseTransparent = "rgba(6, 132, 123, 0.1)";
export var white = "#fff";
export var tabelGray = "#eaeaea";
export var darkGreen = "#06847B";
export var secondaryGrey = "#f6f4eb";
export var secondaryGreyDark = "#9D9FB1";
export var secondaryBlue = "#036DFF";
export var yellow = "#fecc00";
export var yellowDark = "#deae00";
export var yellowLightDark = "#a68500";
export var yellowTransparent = "rgba(254, 204, 0, .6)"; // theme colors

export var theme = {
  activeMenuOption: yellow,
  autofill: autofillColor,
  autofillSelected: autofillColorSelected,
  bannerBackground: secondaryGrey,
  bannerEdge: secondaryGreyDark,
  bannerLink: secondaryBlue,
  baseFont: baseFontColor,
  baseFontColorSemiTransparent: baseFontColorSemiTransparent,
  baseFontColorTransparent: baseFontColorTransparent,
  dark: black,
  disabled: gray,
  divider: grayLight,
  dividerDark: grayMedium,
  error: rose,
  hoverLightBackground: yellow,
  light: grayLight,
  lightFont: secondaryGrey,
  listAttributeName: baseFontColorSemiTransparent,
  listBullet: secondaryGrey,
  overlay: overlayColor,
  primary: yellow,
  primaryDark: yellowDark,
  primaryLight: yellowLightDark,
  primaryTransparent: yellowTransparent,
  secondary: black,
  secondaryDark: black,
  secondaryLight: grayDark,
  secondaryOverlay: grayLight,
  secondaryOverlayDark: gray,
  success: green,
  tabTitle: yellow,
  tableDivider: tabelGray,
  tabsBorder: baseFontColorTransparent,
  thumbnailBorder: yellow,
  darkFont: grayDark,
  white: white,
  black: black
}; // typography

export var baseFontFamily = "'Roboto Slab', serif";
export var baseFontSize = "1rem"; // 16px

export var baseLineHeight = "1.25rem"; // 20px

export var boldFontWeight = 600;
export var extraBoldFontWeight = 800;
export var h1FontSize = "4rem"; // 64px

export var h2FontSize = "3rem"; // 48px

export var h1LineHeight = 1;
export var h3FontSize = "1.5rem"; // 24px

export var h4FontSize = "1.125rem"; // 18px

export var labelFontSize = "0.75rem"; // 12px

export var smallFontSize = "0.875rem"; // 14px

export var ultraBigFont = "6rem"; // 96px
// spacing

export var spacer = 1; // rem

export var fieldSpacer = "1.875rem"; // breakpoints

export var xxxLargeScreen = 1920;
export var xxLargeScreen = 1600;
export var xLargeScreen = 1280;
export var largeScreen = 992;
export var mediumScreen = 720;
export var smallScreen = 540;