import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import React from "react";
import { FormattedMessage } from "react-intl";
import { Icon } from "../Icon";
import { Tile } from "../Tile";
import * as S from "./styles";
export var AddNewTile = function AddNewTile(_ref) {
  var type = _ref.type,
      props = _objectWithoutProperties(_ref, ["type"]);

  return React.createElement(Tile, _extends({
    tileType: "addNew"
  }, props), React.createElement(S.Content, null, React.createElement("p", null, React.createElement(Icon, {
    size: 24,
    name: "plus"
  })), React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "@next.components.atoms.AddNewTile.AddNewTile.1076301920",
    defaultMessage: "Dodaj nowy {type}",
    values: {
      type: type
    }
  }))));
};