import _defineProperty from "@babel/runtime/helpers/defineProperty";
import React from "react";
import { useIntl, FormattedMessage } from "react-intl";
import { Address, DropdownMenu, IconButton, Tile } from "@components/atoms";
import * as S from "./styles";
var defaultShippingAddress = React.createElement(S.MenuItem, null, React.createElement(FormattedMessage, {
  id: "@next.components.molecules.AddressTile.AddressTile.164941860",
  defaultMessage: "Ustaw jako domy\u015Blny adres dostawy"
}));
var defaultBillingAddress = React.createElement(S.MenuItem, null, React.createElement(FormattedMessage, {
  id: "@next.components.molecules.AddressTile.AddressTile.4006302880",
  defaultMessage: "Ustaw jako domy\u015Blny adres rozliczeniowy"
}));
export var AddressTile = function AddressTile(_ref) {
  var onEdit = _ref.onEdit,
      onRemove = _ref.onRemove,
      setDefault = _ref.setDefault,
      address = _ref.address;
  var intl = useIntl();
  var header = React.createElement(S.HeaderContent, null, React.createElement(DropdownMenu, {
    type: "clickable",
    header: React.createElement(IconButton, {
      testingContext: "expandButton",
      name: "expand",
      size: 24
    }),
    items: [{
      content: defaultBillingAddress,
      onClick: function onClick() {
        setDefault("BILLING");
      },
      testingContext: "set-billing"
    }, {
      content: defaultShippingAddress,
      onClick: function onClick() {
        setDefault("SHIPPING");
      },
      testingContext: "set-shipping"
    }]
  }), address.isDefaultBillingAddress && address.isDefaultShippingAddress ? intl.formatMessage(_defineProperty({
    defaultMessage: "Domyślny adres",
    "id": "@next.components.molecules.AddressTile.AddressTile.3986485479"
  }, "id", "@next.components.molecules.AddressTile.AddressTile.3986485479")) : address.isDefaultShippingAddress ? intl.formatMessage(_defineProperty({
    defaultMessage: "Domyślny adres dostawy",
    "id": "@next.components.molecules.AddressTile.AddressTile.3109135064"
  }, "id", "@next.components.molecules.AddressTile.AddressTile.3109135064")) : address.isDefaultBillingAddress ? intl.formatMessage(_defineProperty({
    defaultMessage: "Domyślny adres rozliczeniowy",
    "id": "@next.components.molecules.AddressTile.AddressTile.3194369794"
  }, "id", "@next.components.molecules.AddressTile.AddressTile.3194369794")) : null);
  var footer = React.createElement(S.FooterContent, null, React.createElement("div", null, React.createElement(IconButton, {
    testingContext: "editButton",
    name: "edit",
    onClick: onEdit,
    size: 22
  })), React.createElement("div", null, React.createElement(IconButton, {
    testingContext: "removeButton",
    name: "trash",
    onClick: onRemove,
    size: 19
  })));
  var content = React.createElement(Address, address);
  return React.createElement(S.Wrapper, {
    "data-test-billing-default": address.isDefaultBillingAddress,
    "data-test-shipping-default": address.isDefaultShippingAddress
  }, React.createElement(Tile, {
    footer: footer,
    header: header
  }, content));
};