import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "../..";

var Empty = function Empty(_ref) {
  var overlayHide = _ref.overlayHide;
  return React.createElement("div", {
    className: "cart__empty"
  }, React.createElement("h4", null, React.createElement(FormattedMessage, {
    id: "components.OverlayManager.Cart.Empty.1002597914",
    defaultMessage: "Tw\xF3j koszyk jest pusty"
  })), React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "components.OverlayManager.Cart.Empty.1110028871",
    defaultMessage: "Koszyk jest jeszcze pusty, ale na pewno znajdziesz co\u015B dla siebie w naszym sklepie"
  })), React.createElement("div", {
    className: "cart__empty__action"
  }, React.createElement(Button, {
    testingContext: "emptyCartHideOverlayButton",
    secondary: true,
    onClick: overlayHide
  }, React.createElement(FormattedMessage, {
    id: "components.OverlayManager.Cart.Empty.1062494713",
    defaultMessage: "Kontynuuj zakupy"
  }))));
};

export default Empty;