import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { ProductTile } from "@components/molecules";
import { getCurrentPathWithExtraPageParams } from "@temp/core/utils";
import { Pagination } from "@components/organisms/Pagination";
import { useQueryParams } from "@temp/hooks";
import { PRODUCTS_PER_PAGE } from "@temp/core/config";
import { Button, Loader } from "@components/atoms";
import { FormattedMessage } from "react-intl";
import { BackInStockSubscribeModal } from "@components/organisms/BackInStockSubscribeModal";
import * as S from "./styles";
export var ProductList = function ProductList(_ref) {
  var products = _ref.products,
      totalCount = _ref.totalCount,
      _ref$loading = _ref.loading,
      loading = _ref$loading === void 0 ? false : _ref$loading,
      onLoadMore = _ref.onLoadMore,
      testingContextId = _ref.testingContextId;
  var history = useHistory();
  var queryParams = useQueryParams();
  var currentPage = queryParams.page ? Number(queryParams.page) : 1;
  var totalPages = totalCount ? Math.ceil(totalCount / PRODUCTS_PER_PAGE) : 0;

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      showSubscribeModal = _useState2[0],
      setShowSubscribeModal = _useState2[1];

  var _useState3 = useState(),
      _useState4 = _slicedToArray(_useState3, 2),
      productForSubscribeModal = _useState4[0],
      setProductForSubscribeModal = _useState4[1];

  var onPageChange = function onPageChange(event, page) {
    return history.push(getCurrentPathWithExtraPageParams(page.toString()));
  };

  return React.createElement(React.Fragment, null, React.createElement(S.List, {
    "data-test": "productList",
    "data-test-id": testingContextId
  }, products.map(function (product) {
    var id = product.id,
        name = product.name;
    return id && name && React.createElement(ProductTile, {
      key: id,
      product: product,
      onSubscribe: function onSubscribe() {
        setProductForSubscribeModal(product);
        setShowSubscribeModal(true);
      }
    });
  })), React.createElement(S.Loader, null, loading && React.createElement(Loader, null)), React.createElement(S.Loader, null, !loading && onLoadMore && React.createElement(Button, {
    testingContext: "loadMoreProductsButton",
    color: "primary",
    onClick: onLoadMore
  }, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.ProductList.ProductList.3895212706",
    defaultMessage: "Za\u0142aduj wi\u0119cej +"
  }))), React.createElement(S.Loader, null, totalCount && !loading && totalPages > 1 && React.createElement(Pagination, {
    page: currentPage,
    count: totalPages,
    onChange: onPageChange
  })), React.createElement(BackInStockSubscribeModal, {
    show: showSubscribeModal,
    hideModal: function hideModal() {
      return setShowSubscribeModal(false);
    },
    productData: productForSubscribeModal
  }));
};