var getVariantsStructuredData = function getVariantsStructuredData(variants) {
  var inStock = "https://schema.org/InStock";
  var outOfStock = "https://schema.org/OutOfStock";
  var today = new Date();
  var month = String(today.getMonth() < 10 ? today.getMonth() + 3 : today.getMonth() - 9).padStart(2, "0");
  var year = today.getMonth() < 10 ? today.getFullYear() : today.getFullYear() + 1;
  return variants.map(function (variant) {
    return {
      "@type": "Offer",
      availability: variant.isAvailable ? inStock : outOfStock,
      itemCondition: "https://schema.org/NewCondition",
      price: variant.pricing.price.gross.amount.toFixed(2),
      priceCurrency: variant.pricing.price.gross.currency,
      priceValidUntil: "".concat(year, "-").concat(month, "-15"),
      url: location.href,
      sku: variant.sku
    };
  });
};

export var structuredData = function structuredData(product, variantId) {
  var images = product.images.map(function (image) {
    return new URL(image.url).pathname;
  });
  var variants = product.variants,
      attributes = product.attributes;
  var currentVariant = variants.find(function (e) {
    return e.id === variantId;
  });
  var producerAttribute = attributes.find(function (e) {
    return e.attribute.name === "Producer" && e.values.length;
  });
  return JSON.stringify({
    "@context": "https://schema.org/",
    "@type": "Product",
    brand: {
      "@type": "Brand",
      name: producerAttribute === null || producerAttribute === void 0 ? void 0 : producerAttribute.values[0].name
    },
    description: !product.seoDescription ? "".concat(product.description) : "".concat(product.seoDescription),
    image: images,
    name: !product.seoTitle ? "".concat(product.name) : "".concat(product.seoTitle),
    offers: getVariantsStructuredData(variants),
    url: location.href,
    sku: currentVariant === null || currentVariant === void 0 ? void 0 : currentVariant.sku,
    mpn: currentVariant === null || currentVariant === void 0 ? void 0 : currentVariant.sku
  });
};