import _extends from "@babel/runtime/helpers/extends";
import React from "react";
import { AddNewTile, TileGrid } from "@components/atoms";
import { AddressTile } from "@components/molecules";

/**
 * Addresses tiles with add new address tile opening address form addition modal.
 */
export var AddressGrid = function AddressGrid(_ref) {
  var addresses = _ref.addresses,
      addNewAddress = _ref.addNewAddress;
  var addNewTile = React.createElement(AddNewTile, {
    key: "newTile",
    type: "adres",
    onClick: addNewAddress
  });
  var addressTiles = addresses.reduce(function (elements, address) {
    elements.push(React.createElement(AddressTile, _extends({
      key: "addressTile-".concat(address.id)
    }, address)));
    return elements;
  }, [addNewTile]);
  return React.createElement(TileGrid, {
    columns: 2,
    elements: addressTiles
  });
};