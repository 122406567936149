import React from "react";
import { ErrorMessage, Radio } from "@components/atoms";
import { PROVIDERS } from "@temp/core/config";
import { useCheckout } from "@saleor/sdk";
import { DummyPaymentGateway, CashPaymentGateway, PayUPaymentGateway, BankTransferPaymentGateway, payOnDeliveryPostfix } from "..";
import * as S from "./styles";
import { Money } from "../../containers/Money/Money";
/**
 * Payment Gateways list
 */

var PaymentGatewaysList = function PaymentGatewaysList(_ref) {
  var paymentGateways = _ref.paymentGateways,
      selectedPaymentGateway = _ref.selectedPaymentGateway,
      selectedPaymentGatewayToken = _ref.selectedPaymentGatewayToken,
      selectPaymentGateway = _ref.selectPaymentGateway,
      formRef = _ref.formRef,
      formId = _ref.formId,
      _processPayment = _ref.processPayment,
      submitPayment = _ref.submitPayment,
      submitPaymentSuccess = _ref.submitPaymentSuccess,
      errors = _ref.errors,
      onError = _ref.onError;

  var _useCheckout = useCheckout(),
      checkout = _useCheckout.checkout,
      availableShippingMethods = _useCheckout.availableShippingMethods;

  var currentShippingMethod = checkout === null || checkout === void 0 ? void 0 : checkout.shippingMethod;
  var shippingMethod = currentShippingMethod && (currentShippingMethod === null || currentShippingMethod === void 0 ? void 0 : currentShippingMethod.name.toLocaleLowerCase().endsWith(payOnDeliveryPostfix.toLocaleLowerCase())) ? availableShippingMethods === null || availableShippingMethods === void 0 ? void 0 : availableShippingMethods.find(function (item) {
    return "".concat(item.name, " ").concat(payOnDeliveryPostfix).toLocaleLowerCase() === (currentShippingMethod === null || currentShippingMethod === void 0 ? void 0 : currentShippingMethod.name.toLocaleLowerCase());
  }) : currentShippingMethod;
  var payOnDeliveryShippingMethod = shippingMethod && (availableShippingMethods === null || availableShippingMethods === void 0 ? void 0 : availableShippingMethods.find(function (item) {
    return "".concat(shippingMethod.name, " ").concat(payOnDeliveryPostfix).toLocaleLowerCase() === (item === null || item === void 0 ? void 0 : item.name.toLocaleLowerCase());
  }));
  return React.createElement(S.Wrapper, null, paymentGateways.map(function (_ref2, index) {
    var id = _ref2.id,
        name = _ref2.name,
        config = _ref2.config;
    var checked = selectedPaymentGateway === id;

    switch (name) {
      case PROVIDERS.DUMMY.label:
        return React.createElement("div", {
          key: index
        }, React.createElement(S.Tile, {
          checked: checked
        }, React.createElement(Radio, {
          "data-test": "checkoutPaymentGatewayDummyInput",
          name: "payment-method",
          value: "dummy",
          checked: checked,
          onChange: function onChange() {
            return selectPaymentGateway && selectPaymentGateway(id);
          },
          customLabel: true
        }, React.createElement("span", {
          "data-test": "checkoutPaymentGatewayDummyName"
        }, name))), checked && React.createElement(DummyPaymentGateway, {
          formRef: formRef,
          formId: formId,
          processPayment: function processPayment(token) {
            var _ref3;

            return _processPayment(id, token, undefined, (_ref3 = shippingMethod === null || shippingMethod === void 0 ? void 0 : shippingMethod.id) !== null && _ref3 !== void 0 ? _ref3 : undefined);
          },
          initialStatus: selectedPaymentGatewayToken
        }));

      case PROVIDERS.CASH.label:
        return React.createElement("div", {
          key: index
        }, React.createElement(S.Tile, {
          checked: checked
        }, React.createElement(Radio, {
          "data-test": "checkoutPaymentGatewayCashInput",
          name: "payment-method",
          value: "cash",
          checked: checked,
          onChange: function onChange() {
            return selectPaymentGateway && selectPaymentGateway(id);
          },
          customLabel: true
        }, React.createElement("span", {
          "data-test": "checkoutPaymentGatewayCashName"
        }, "P\u0142atno\u015B\u0107 przy odbiorze", shippingMethod && payOnDeliveryShippingMethod && React.createElement(React.Fragment, null, " ", "| +", " ", React.createElement(Money, {
          "data-test": "checkoutShippingMethodOptionPrice",
          money: {
            // @ts-ignore
            currency: shippingMethod.price.currency,
            amount: // @ts-ignore
            payOnDeliveryShippingMethod.price.amount - shippingMethod.price.amount
          }
        }))))), checked && React.createElement(CashPaymentGateway, {
          formRef: formRef,
          formId: formId,
          processPayment: function processPayment(token) {
            var _ref4;

            return _processPayment(id, token, undefined, (_ref4 = payOnDeliveryShippingMethod === null || payOnDeliveryShippingMethod === void 0 ? void 0 : payOnDeliveryShippingMethod.id) !== null && _ref4 !== void 0 ? _ref4 : undefined);
          }
        }));

      case PROVIDERS.PAYU.label:
        return React.createElement("div", {
          key: index
        }, React.createElement(S.Tile, {
          checked: checked
        }, React.createElement(Radio, {
          "data-test": "checkoutPaymentGatewayPayUInput",
          name: "payment-method",
          value: "payu",
          checked: checked,
          onChange: function onChange() {
            return selectPaymentGateway && selectPaymentGateway(id);
          },
          customLabel: true
        }, React.createElement("span", {
          "data-test": "checkoutPaymentGatewayPayUName"
        }, name))), checked && React.createElement(PayUPaymentGateway, {
          formRef: formRef,
          formId: formId,
          processPayment: function processPayment(token) {
            var _ref5;

            return _processPayment(id, token, undefined, (_ref5 = shippingMethod === null || shippingMethod === void 0 ? void 0 : shippingMethod.id) !== null && _ref5 !== void 0 ? _ref5 : undefined);
          }
        }));

      case PROVIDERS.BANKTRANSFER.label:
        return React.createElement("div", {
          key: index
        }, React.createElement(S.Tile, {
          checked: checked
        }, React.createElement(Radio, {
          "data-test": "checkoutPaymentGatewayBankTransferInput",
          name: "payment-method",
          value: "bank-transfer",
          checked: checked,
          onChange: function onChange() {
            return selectPaymentGateway && selectPaymentGateway(id);
          },
          customLabel: true
        }, React.createElement("span", {
          "data-test": "checkoutPaymentGatewayBankTransferName"
        }, "Przelew bankowy"))), checked && React.createElement(BankTransferPaymentGateway, {
          formRef: formRef,
          formId: formId,
          processPayment: function processPayment(token) {
            var _ref6;

            return _processPayment(id, token, undefined, (_ref6 = shippingMethod === null || shippingMethod === void 0 ? void 0 : shippingMethod.id) !== null && _ref6 !== void 0 ? _ref6 : undefined);
          }
        }));

      default:
        return null;
    }
  }), !selectedPaymentGateway && errors && React.createElement(ErrorMessage, {
    errors: errors
  }));
};

export { PaymentGatewaysList };