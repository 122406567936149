import _extends from "@babel/runtime/helpers/extends";
import _objectWithoutProperties from "@babel/runtime/helpers/objectWithoutProperties";
import React from "react";
import { ErrorMessage, Input } from "@components/atoms";
import * as S from "./styles";
export var TextField = function TextField(_ref) {
  var errors = _ref.errors,
      helpText = _ref.helpText,
      _ref$formBackgroundCo = _ref.formBackgroundColor,
      formBackgroundColor = _ref$formBackgroundCo === void 0 ? "black" : _ref$formBackgroundCo,
      rest = _objectWithoutProperties(_ref, ["errors", "helpText", "formBackgroundColor"]);

  var hasErrors = !!(errors && errors.length);
  return React.createElement(React.Fragment, null, React.createElement(S.TextField, null, React.createElement(Input, _extends({}, rest, {
    error: hasErrors,
    formBackgroundColor: formBackgroundColor
  })), React.createElement(S.ErrorMessages, null, React.createElement(ErrorMessage, {
    errors: errors
  }), helpText && React.createElement(S.HelpText, {
    formBackgroundColor: formBackgroundColor
  }, helpText))));
};