import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import { Formik } from "formik";
import React, { useState } from "react";
import { useIntl } from "react-intl";
import { AddNewTile, ErrorMessage, TileGrid } from "@components/atoms";
import { AddressTileOption } from "@components/molecules";
import { checkoutMessages } from "@temp/intl";
import { AddressFormModal } from "../AddressFormModal";

/**
 * Addresses tiles to select with add new address tile opening address form addition modal.
 */
var AddressGridSelector = function AddressGridSelector(_ref) {
  var addresses = _ref.addresses,
      selectedAddressId = _ref.selectedAddressId,
      countriesOptions = _ref.countriesOptions,
      userId = _ref.userId,
      errors = _ref.errors,
      onSelect = _ref.onSelect,
      formId = _ref.formId,
      formRef = _ref.formRef,
      addNewModalTarget = _ref.addNewModalTarget,
      newAddressFormId = _ref.newAddressFormId,
      testingContext = _ref.testingContext;

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      displayNewModal = _useState2[0],
      setDisplayNewModal = _useState2[1];

  var intl = useIntl();
  var addNewTile = React.createElement(AddNewTile, {
    "data-test": "".concat(testingContext, "AddressTileAddNew"),
    key: "newTile",
    type: intl.formatMessage(_defineProperty({
      defaultMessage: "address",
      "id": "@next.components.organisms.AddressGridSelector.AddressGridSelector.2132027491"
    }, "id", "@next.components.organisms.AddressGridSelector.AddressGridSelector.2132027491")),
    onClick: function onClick() {
      return setDisplayNewModal(true);
    }
  });
  return React.createElement(React.Fragment, null, React.createElement(Formik, {
    initialValues: {
      addressTileOption: selectedAddressId
    },
    enableReinitialize: true,
    onSubmit: function onSubmit(values, _ref2) {
      var setSubmitting = _ref2.setSubmitting;

      if (onSelect) {
        var address = addresses.find(function (addr) {
          return addr.id === values.addressTileOption;
        });
        onSelect(address === null || address === void 0 ? void 0 : address.address, values.addressTileOption);
      }

      setSubmitting(false);
    }
  }, function (_ref3) {
    var handleChange = _ref3.handleChange,
        handleSubmit = _ref3.handleSubmit,
        handleBlur = _ref3.handleBlur,
        values = _ref3.values,
        setFieldValue = _ref3.setFieldValue,
        setFieldTouched = _ref3.setFieldTouched;
    return React.createElement("form", {
      id: formId,
      ref: formRef,
      onSubmit: handleSubmit
    }, React.createElement(TileGrid, {
      columns: 2,
      elements: addresses.reduce(function (elements, _ref4, index) {
        var id = _ref4.id,
            address = _ref4.address;
        elements.push(React.createElement(AddressTileOption, {
          testingContext: testingContext,
          "data-test": "".concat(testingContext, "AddressTileOption"),
          "data-test-id": index,
          key: "addressTile-".concat(id),
          id: id,
          inputName: "addressTileOption",
          address: address,
          onChange: function onChange() {
            return setFieldValue("addressTileOption", id);
          },
          checked: !!values.addressTileOption && values.addressTileOption === id
        }));
        return elements;
      }, [addNewTile])
    }), React.createElement(ErrorMessage, {
      errors: errors
    }));
  }), displayNewModal && React.createElement(AddressFormModal, {
    hideModal: function hideModal() {
      setDisplayNewModal(false);
    },
    submitBtnText: "Dodaj",
    title: intl.formatMessage(checkoutMessages.addNewAddress),
    countriesOptions: countriesOptions,
    formId: newAddressFormId,
    userId: userId,
    target: addNewModalTarget
  }));
};

export { AddressGridSelector };