import React from "react";
import { Icon } from "../Icon";
import * as S from "./styles";
export var SocialMediaIcon = function SocialMediaIcon(_ref) {
  var medium = _ref.medium,
      target = _ref.target;
  return React.createElement(S.Wrapper, null, React.createElement(S.Link, {
    href: medium.href,
    target: target || "_blank",
    "aria-label": medium.ariaLabel
  }, React.createElement(Icon, {
    name: medium.iconName,
    size: 36,
    color: "#fecc00"
  })));
};