import { Formik } from "formik";
import React from "react";
import * as S from "./styles";

/**
 * PayU payment gateway.
 */
var CashPaymentGateway = function CashPaymentGateway(_ref) {
  var processPayment = _ref.processPayment,
      formRef = _ref.formRef,
      formId = _ref.formId;
  return React.createElement(Formik, {
    initialValues: {},
    onSubmit: function onSubmit(_, _ref2) {
      var setSubmitting = _ref2.setSubmitting;
      processPayment("cash-token");
      setSubmitting(false);
    }
  }, function (_ref3) {
    var handleSubmit = _ref3.handleSubmit;
    return React.createElement(S.Form, {
      id: formId,
      ref: formRef,
      onSubmit: handleSubmit,
      "data-test": "cashPaymentGatewayForm"
    });
  });
};

export { CashPaymentGateway };