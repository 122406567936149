import React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { Button, OverlayTheme, OverlayType } from "..";

var CheckoutAsGuest = function CheckoutAsGuest(_ref) {
  var overlay = _ref.overlay,
      checkoutUrl = _ref.checkoutUrl;
  return React.createElement("div", {
    className: "checkout-login__guest"
  }, React.createElement("h3", {
    className: "checkout__header"
  }, React.createElement(FormattedMessage, {
    id: "components.CheckoutLogin.CheckoutAsGuest.3719062509",
    defaultMessage: "Zam\xF3w bez rejestracji"
  })), React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "components.CheckoutLogin.CheckoutAsGuest.2134230481",
    defaultMessage: "Je\u017Celi nie chcesz zak\u0142ada\u0107 konta, mo\u017Cesz z\u0142o\u017Cy\u0107 zam\xF3wienie bez rejestracji."
  })), React.createElement(Link, {
    to: checkoutUrl
  }, React.createElement(Button, {
    testingContext: "continueAsGuestButton"
  }, React.createElement(FormattedMessage, {
    id: "components.CheckoutLogin.CheckoutAsGuest.3719062509",
    defaultMessage: "Zam\xF3w bez rejestracji"
  }))), React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "components.CheckoutLogin.CheckoutAsGuest.2231885220",
    defaultMessage: "lub"
  }), " ", React.createElement("span", {
    "data-test": "showRegisterOverlay",
    className: "u-link",
    onClick: function onClick() {
      return overlay.show(OverlayType.register, OverlayTheme.right);
    }
  }, React.createElement(FormattedMessage, {
    id: "components.CheckoutLogin.CheckoutAsGuest.1053523096",
    defaultMessage: "za\u0142\xF3\u017C konto"
  }))));
};

export default CheckoutAsGuest;