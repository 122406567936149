import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useState, useEffect } from "react";
import { Modal } from "@components/organisms";
import { useAuth } from "@saleor/sdk";
import { TypedBackInStockSubscribeMutation } from "@temp/views/Product/queries";
import { Input } from "@components/atoms";
import * as S from "./styles";
export var BackInStockSubscribeModal = function BackInStockSubscribeModal(_ref) {
  var show = _ref.show,
      hideModal = _ref.hideModal,
      productData = _ref.productData;

  var _useState = useState(),
      _useState2 = _slicedToArray(_useState, 2),
      userEmail = _useState2[0],
      setUserEmail = _useState2[1];

  var _useAuth = useAuth(),
      user = _useAuth.user;

  var handleEmailInputChange = function handleEmailInputChange(event) {
    setUserEmail(event.target.value);
  };

  useEffect(function () {
    if (user) setUserEmail(user.email);
  }, [user]);
  return React.createElement(TypedBackInStockSubscribeMutation, null, function (subscribe, _ref2) {
    var loading = _ref2.loading;
    return React.createElement(Modal, {
      title: "Zapisz si\u0119, aby otrzyma\u0107 powiadomienie o powrocie do sprzeda\u017Cy",
      submitBtnText: "Zapisz",
      show: show,
      disabled: loading,
      hide: hideModal,
      submitButtonTestingContext: "submitBackInStockSubscribeModalButton",
      testingContext: "submitBackInStockSubscribeModal",
      onSubmit: function onSubmit() {
        if (productData) {
          subscribe({
            variables: {
              productId: productData.id,
              email: userEmail
            }
          }).then(hideModal);
        }
      }
    }, React.createElement(S.SelectedProduct, null, "Wybrany produkt: ", productData === null || productData === void 0 ? void 0 : productData.name), "Przepraszamy, nie mamy wystarczaj\u0105cej ilo\u015Bci w magazynie dla wybranego produktu. Je\u015Bli chcesz, mo\u017Cemy wys\u0142a\u0107 Ci powiadomienie, gdy produkt b\u0119dzie ponownie dost\u0119pny.", React.createElement(S.InputWrapper, null, React.createElement(Input, {
      name: "inputCode",
      label: "Email",
      value: userEmail,
      onChange: handleEmailInputChange,
      formBackgroundColor: "white"
    })));
  });
};