import _defineProperty from "@babel/runtime/helpers/defineProperty";
import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import "./scss/index.scss";
import * as React from "react";
import { useIntl } from "react-intl";
import { commonMessages } from "@temp/intl";
import { ProductListHeader } from "@components/molecules";
import { ProductList, FilterSidebar } from "@components/organisms";
import { getAttribute } from "@temp/views/utils";
import { DebounceChange, ProductsFeatured, TextField } from "../../components";
import { maybe } from "../../core/utils";

var Page = function Page(_ref) {
  var activeFilters = _ref.activeFilters,
      activeSortOption = _ref.activeSortOption,
      attributes = _ref.attributes,
      search = _ref.search,
      setSearch = _ref.setSearch,
      displayLoader = _ref.displayLoader,
      clearFilters = _ref.clearFilters,
      products = _ref.products,
      filters = _ref.filters,
      onOrder = _ref.onOrder,
      sortOptions = _ref.sortOptions,
      onAttributeFiltersChange = _ref.onAttributeFiltersChange;
  var canDisplayProducts = maybe(function () {
    return !!products.edges && products.totalCount !== undefined;
  });
  var hasProducts = canDisplayProducts && !!products.totalCount;

  var _React$useState = React.useState(false),
      _React$useState2 = _slicedToArray(_React$useState, 2),
      showFilters = _React$useState2[0],
      setShowFilters = _React$useState2[1];

  var intl = useIntl();
  var activeFiltersAttributes = filters && filters.attributes && Object.keys(filters.attributes).reduce(function (acc, key) {
    return acc.concat(filters.attributes[key].map(function (valueSlug) {
      return getAttribute(attributes, key, valueSlug);
    }));
  }, []);
  return React.createElement("div", {
    className: "category"
  }, React.createElement("div", {
    className: "search-page"
  }, React.createElement("div", {
    className: "search-page__header"
  }, React.createElement("div", {
    className: "search-page__header__input container"
  }, React.createElement(DebounceChange, {
    debounce: function debounce(evt) {
      return setSearch(evt.target.value.toLowerCase());
    },
    value: search,
    time: 500
  }, function (_ref2) {
    var change = _ref2.change,
        value = _ref2.value;
    return React.createElement(TextField, {
      autoFocus: true,
      label: intl.formatMessage(_defineProperty({
        defaultMessage: "Szukany termin:",
        "id": "views.Search.Page.1301562360"
      }, "id", "views.Search.Page.1301562360")),
      onChange: change,
      value: value
    });
  })))), React.createElement("div", {
    className: "container"
  }, React.createElement(FilterSidebar, {
    show: showFilters,
    hide: function hide() {
      return setShowFilters(false);
    },
    onAttributeFiltersChange: onAttributeFiltersChange,
    attributes: attributes,
    filters: filters
  }), React.createElement(ProductListHeader, {
    activeSortOption: activeSortOption,
    openFiltersMenu: function openFiltersMenu() {
      return setShowFilters(true);
    },
    numberOfProducts: products ? products.totalCount : 0,
    activeFilters: activeFilters,
    activeFiltersAttributes: activeFiltersAttributes,
    clearFilters: clearFilters,
    sortOptions: sortOptions,
    onChange: onOrder,
    onCloseFilterAttribute: onAttributeFiltersChange
  }), canDisplayProducts && React.createElement(ProductList, {
    totalCount: products.totalCount,
    products: products.edges.map(function (edge) {
      return edge.node;
    }),
    loading: displayLoader
  })), !hasProducts && React.createElement(ProductsFeatured, {
    title: intl.formatMessage(commonMessages.youMightLike)
  }));
};

export default Page;