import _slicedToArray from "@babel/runtime/helpers/slicedToArray";
import React, { useState } from "react";
import { FormattedMessage, useIntl } from "react-intl";
import { Icon } from "@components/atoms";
import { TaxedMoney } from "@components/containers";
import { CartSummaryRow } from "@components/molecules";
import { commonMessages } from "@temp/intl";
import * as S from "./styles";

var CostLine = function CostLine(_ref) {
  var name = _ref.name,
      cost = _ref.cost,
      _ref$last = _ref.last,
      last = _ref$last === void 0 ? false : _ref$last,
      _ref$negative = _ref.negative,
      negative = _ref$negative === void 0 ? false : _ref$negative;
  return React.createElement(S.CostLine, {
    last: last
  }, React.createElement("span", null, name), React.createElement("span", {
    "data-test": "cartSummaryCost".concat(name.replace(/\s/g, ""))
  }, negative && "- ", React.createElement(TaxedMoney, {
    taxedMoney: cost
  })));
};

var Costs = function Costs(_ref2) {
  var subtotal = _ref2.subtotal,
      promoCode = _ref2.promoCode,
      shipping = _ref2.shipping,
      total = _ref2.total;
  var intl = useIntl();
  return React.createElement(S.Costs, null, subtotal && React.createElement(CostLine, {
    name: intl.formatMessage(commonMessages.subtotal),
    cost: subtotal
  }), shipping && React.createElement(CostLine, {
    name: intl.formatMessage(commonMessages.shipping),
    cost: shipping
  }), promoCode && promoCode.gross.amount > 0 && React.createElement(CostLine, {
    name: intl.formatMessage(commonMessages.promoCode),
    cost: promoCode,
    negative: true
  }), total && React.createElement(CostLine, {
    name: intl.formatMessage(commonMessages.total),
    cost: total,
    last: true
  }));
};
/**
 * Cart summary displayed in checkout page
 */


var CartSummary = function CartSummary(_ref3) {
  var subtotal = _ref3.subtotal,
      total = _ref3.total,
      shipping = _ref3.shipping,
      promoCode = _ref3.promoCode,
      products = _ref3.products;

  var _useState = useState(false),
      _useState2 = _slicedToArray(_useState, 2),
      mobileCartOpened = _useState2[0],
      setMobileCartOpened = _useState2[1];

  return React.createElement(S.Wrapper, {
    mobileCartOpened: mobileCartOpened
  }, React.createElement(S.Title, {
    "data-test": "cartSummaryTitle",
    onClick: function onClick() {
      return setMobileCartOpened(!mobileCartOpened);
    }
  }, React.createElement(FormattedMessage, {
    id: "@next.components.organisms.CartSummary.CartSummary.2535000644",
    defaultMessage: "Podsumowanie koszyka"
  }), React.createElement(S.ArrowUp, {
    mobileCartOpened: mobileCartOpened
  }, React.createElement(Icon, {
    name: "arrow_up",
    size: 24
  }))), React.createElement(S.Content, null, React.createElement(S.HR, null), React.createElement(S.CartSummaryProductList, null, products === null || products === void 0 ? void 0 : products.map(function (product, index) {
    return React.createElement("div", {
      key: product.sku
    }, React.createElement(S.ProductLine, null, React.createElement(CartSummaryRow, {
      index: index,
      sku: product.sku,
      quantity: product.quantity,
      name: product.name,
      price: product.price,
      thumbnail: product.thumbnail
    })), React.createElement(S.HR, null));
  })), React.createElement(Costs, {
    subtotal: subtotal,
    total: total,
    shipping: shipping,
    promoCode: promoCode
  })));
};

export { CartSummary };