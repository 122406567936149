import React from "react";
import { FormattedMessage } from "react-intl";
import { Button } from "@components/atoms";
export var AddToCartButton = function AddToCartButton(_ref) {
  var onSubmit = _ref.onSubmit,
      disabled = _ref.disabled;
  return React.createElement(Button, {
    fullWidth: true,
    testingContext: "addProductToCartButton",
    onClick: onSubmit,
    color: "primary",
    disabled: disabled
  }, React.createElement(FormattedMessage, {
    id: "@next.components.molecules.AddToCartButton.AddToCartButton.332283140",
    defaultMessage: "Dodaj do koszyka"
  }));
};
AddToCartButton.displayName = "AddToCartButton";
export default AddToCartButton;