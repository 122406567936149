import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  position: absolute;\n  right: 1rem;\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  position: absolute;\n  right: 1rem;\n  transition-duration: 0.3s;\n  transform: ", ";\n  path {\n    fill: ", ";\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral([""]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject());
export var DropdownIndicator = styled.div(_templateObject2(), function (props) {
  return props.rotate === "true" ? "rotate(180deg)" : "rotate(0deg)";
}, function (props) {
  return props.formBackgroundColor === "black" ? props.theme.colors.primary : props.theme.colors.darkFont;
});
export var ClearIndicator = styled.div(_templateObject3());