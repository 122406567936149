import { IN_STOCK_ONLY_FILTER } from "@components/organisms/FilterSidebar/constants";
export var getAttribute = function getAttribute(attributes, attributeSlug, valueSlug) {
  if (attributeSlug === IN_STOCK_ONLY_FILTER.slug) {
    return {
      attributeSlug: attributeSlug,
      valueName: IN_STOCK_ONLY_FILTER.name,
      valueSlug: attributeSlug
    };
  }

  return {
    attributeSlug: attributeSlug,
    valueName: attributes.find(function (_ref) {
      var slug = _ref.slug;
      return attributeSlug === slug;
    }).values.find(function (_ref2) {
      var slug = _ref2.slug;
      return valueSlug === slug;
    }).name,
    valueSlug: valueSlug
  };
};