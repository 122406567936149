import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  ", "\n  ", "\n  query RecentProducts($first: Int, $offset: Int, $sortBy: ProductOrder) {\n    products: storeProducts(first: $first, offset: $offset, sortBy: $sortBy) {\n      totalCount\n      edges {\n        node {\n          ...BasicProductFields\n          ...ProductPricingField\n        }\n      }\n      pageInfo {\n        endCursor\n        hasNextPage\n        hasPreviousPage\n        startCursor\n      }\n      totalCount\n    }\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  query ProductsList {\n    shop {\n      description\n      name\n      homepageCollection {\n        id\n        backgroundImage {\n          url\n        }\n        name\n      }\n    }\n    categories(level: 0, first: 4) {\n      edges {\n        node {\n          id\n          name\n          backgroundImage {\n            url\n          }\n        }\n      }\n    }\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import gql from "graphql-tag";
import { basicProductFragment, productPricingFragment } from "@temp/views/Product/queries";
import { TypedQuery } from "../../core/queries";
export var homePageQuery = gql(_templateObject());
export var TypedHomePageQuery = TypedQuery(homePageQuery);
export var recentProductsQuery = gql(_templateObject2(), basicProductFragment, productPricingFragment);
export var TypedRecentProductsQuery = TypedQuery(recentProductsQuery);