import * as React from "react";
import { FormattedMessage } from "react-intl";
export var NothingFound = function NothingFound(_ref) {
  var search = _ref.search;
  return React.createElement("div", {
    className: "search__products--not-found"
  }, React.createElement("p", {
    className: "u-lead u-lead--bold u-uppercase"
  }, React.createElement(FormattedMessage, {
    id: "components.OverlayManager.Search.NothingFound.1893589925",
    defaultMessage: "Przykro nam, ale nie mogli\u015Bmy dopasowa\u0107 \u017Cadnego wyniku dla frazy: {search}",
    values: {
      search: search
    }
  })), React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "components.OverlayManager.Search.NothingFound.1103414499",
    defaultMessage: "Nie poddawaj si\u0119 - sprawd\u017A pisowni\u0119, pomy\u015Bl o mniej konkretnej frazie i wyszukaj ponownie."
  })));
};
export default NothingFound;