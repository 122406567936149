import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject9() {
  var data = _taggedTemplateLiteral([""]);

  _templateObject9 = function _templateObject9() {
    return data;
  };

  return data;
}

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n    margin: 30px 0;\n  "]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  margin: 40px 0;\n\n  ", "\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n    margin: 30px 0;\n  "]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  display: block;\n  height: 1px;\n  border: 0;\n  border-top: 1px solid ", ";\n  margin: 40px 0;\n  padding: 0;\n\n  ", "\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n    font-size: ", ";\n  "]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  font-size: ", ";\n\n  ", "\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n    margin: 80px 0 80px 0;\n  "]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  color: ", ";\n  margin: 100px 0 100px 0;\n\n  ", "\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { media, styled } from "@styles";
export var Wrapper = styled.div(_templateObject(), function (props) {
  return props.theme.colors.secondaryOverlayDark;
}, media.mediumScreen(_templateObject2()));
export var TitleFirstLine = styled.h1(_templateObject3(), function (props) {
  return props.theme.typography.h1FontSize;
}, function (props) {
  return media.mediumScreen(_templateObject4(), props.theme.typography.h2FontSize);
});
export var HR = styled.hr(_templateObject5(), function (props) {
  return props.theme.colors.baseFontColorTransparent;
}, media.mediumScreen(_templateObject6()));
export var Subtitle = styled.p(_templateObject7(), media.mediumScreen(_templateObject8()));
export var ContinueButton = styled.div(_templateObject9());