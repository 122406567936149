import _objectSpread from "@babel/runtime/helpers/objectSpread";
import "./scss/index.scss";
import * as React from "react";
import { MetaWrapper, NotFound } from "../../components";
import { STATIC_PAGES } from "../../core/config";
import { generatePageUrl, maybe } from "../../core/utils";
import Page from "./Page";
import { TypedArticleQuery } from "./query";

var canDisplay = function canDisplay(page) {
  return maybe(function () {
    return !!page && !!page.title && (!!page.contentHtml || page.contentHtml === "");
  });
};

var getHeaderImage = function getHeaderImage(shop) {
  return maybe(function () {
    return shop.homepageCollection.backgroundImage.url;
  });
};

export var View = function View(_ref) {
  var slug = _ref.match.params.slug;
  return React.createElement(TypedArticleQuery, {
    loaderFull: true,
    variables: {
      slug: slug
    },
    errorPolicy: "all"
  }, function (_ref2) {
    var data = _ref2.data;
    var navigation = STATIC_PAGES.map(function (page) {
      return _objectSpread({}, page, {
        active: page.url === window.location.pathname
      });
    });
    var page = data.page,
        shop = data.shop;

    if (canDisplay(page)) {
      var breadcrumbs = [{
        link: generatePageUrl(slug),
        value: page.title
      }];
      return React.createElement(MetaWrapper, {
        meta: {
          description: page.seoDescription,
          title: page.seoTitle
        }
      }, React.createElement(Page, {
        breadcrumbs: breadcrumbs,
        headerImage: getHeaderImage(shop),
        navigation: navigation,
        page: data.page
      }));
    }

    if (page === null) {
      return React.createElement(NotFound, null);
    }
  });
};
export default View;