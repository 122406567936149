import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject8() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  width: 100%;\n  justify-content: space-between;\n  align-items: center;\n  position: relative;\n"]);

  _templateObject8 = function _templateObject8() {
    return data;
  };

  return data;
}

function _templateObject7() {
  var data = _taggedTemplateLiteral(["\n  display: flex;\n  align-items: center;\n  position: relative;\n  &:not(:last-child) {\n    width: 100%;\n  }\n"]);

  _templateObject7 = function _templateObject7() {
    return data;
  };

  return data;
}

function _templateObject6() {
  var data = _taggedTemplateLiteral(["\n  z-index: -1;\n  width: 100%;\n  height: 4px;\n  background-color: ", ";\n"]);

  _templateObject6 = function _templateObject6() {
    return data;
  };

  return data;
}

function _templateObject5() {
  var data = _taggedTemplateLiteral(["\n  transform: none;\n  top: 35px;\n  right: 0;\n"]);

  _templateObject5 = function _templateObject5() {
    return data;
  };

  return data;
}

function _templateObject4() {
  var data = _taggedTemplateLiteral(["\n  transform: none;\n  top: 35px;\n"]);

  _templateObject4 = function _templateObject4() {
    return data;
  };

  return data;
}

function _templateObject3() {
  var data = _taggedTemplateLiteral(["\n  white-space: pre;\n  display: block;\n  position: absolute;\n  top: 35px;\n  transform: translateX(-50%);\n  font-size: ", ";\n  letter-spacing: 2%;\n  color: ", ";\n"]);

  _templateObject3 = function _templateObject3() {
    return data;
  };

  return data;
}

function _templateObject2() {
  var data = _taggedTemplateLiteral(["\n  width: 12px;\n  height: 12px;\n  border-radius: 50%;\n  box-shadow: 0 0 0 4px ", ";\n  div {\n    border: 6px solid ", ";\n  }\n"]);

  _templateObject2 = function _templateObject2() {
    return data;
  };

  return data;
}

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  position: relative;\n  border-radius: 50%;\n  width: 12px;\n  height: 12px;\n  border: 6px solid\n    ", ";\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Dot = styled.div(_templateObject(), function (props) {
  return props.done ? props.theme.colors.primary : "#c2c2c2";
});
export var ActiveDot = styled.div(_templateObject2(), function (props) {
  return props.theme.colors.primary;
}, function (props) {
  return props.theme.colors.black;
});
export var Label = styled.span(_templateObject3(), [function (props) {
  return props.theme.typography.smallFontSize;
}], function (props) {
  return props.theme.colors.lightFont;
});
export var LeftLabel = styled(Label)(_templateObject4());
export var RightLabel = styled(Label)(_templateObject5());
export var ProgressBar = styled.div(_templateObject6(), function (props) {
  return props.done ? props.theme.colors.primary : "#c2c2c2";
});
export var Step = styled.div(_templateObject7());
export var Wrapper = styled.div(_templateObject8());