import _taggedTemplateLiteral from "@babel/runtime/helpers/taggedTemplateLiteral";

function _templateObject() {
  var data = _taggedTemplateLiteral(["\n  line-height: 1.6;\n  font-size: ", ";\n  color: ", ";\n\n  strong {\n    font-weight: ", ";\n    display: inline-block;\n  }\n"]);

  _templateObject = function _templateObject() {
    return data;
  };

  return data;
}

import { styled } from "@styles";
export var Wrapper = styled.div(_templateObject(), function (props) {
  return props.theme.typography.h4FontSize;
}, function (props) {
  return props.theme.colors.light;
}, function (props) {
  return props.theme.typography.boldFontWeight;
});