import "./scss/index.scss";
import * as React from "react";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";
import { BASE_URL } from "../../core/config";
import Button from "../Button";

var NotFound = function NotFound() {
  return React.createElement("div", {
    className: "not-found-page"
  }, React.createElement("h2", {
    className: "not-found-page__header"
  }, React.createElement(FormattedMessage, {
    id: "components.NotFound.index.3478767873",
    defaultMessage: "404"
  })), React.createElement("div", {
    className: "not-found-page__ruler"
  }), React.createElement("div", {
    className: "not-found-page__message"
  }, React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "components.NotFound.index.2080870842",
    defaultMessage: "Nie mo\u017Cemy znale\u017A\u0107 strony, kt\xF3rej szukasz!"
  }), " "), React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "components.NotFound.index.1696903156",
    defaultMessage: "By\u0107 mo\u017Ce b\u0142\u0119dnie wpisa\u0142e\u015B adres lub strona zosta\u0142a przeniesiona."
  }), " "), React.createElement("p", null, React.createElement(FormattedMessage, {
    id: "components.NotFound.index.119718824",
    defaultMessage: "Przepraszamy za b\u0142\u0105d i mamy nadziej\u0119, \u017Ce b\u0119dziesz mia\u0142 dobry dzie\u0144."
  }))), React.createElement("div", {
    className: "not-found-page__button"
  }, React.createElement(Link, {
    to: BASE_URL
  }, React.createElement(Button, {
    testingContext: "404pageGotoHomeButton",
    secondary: true
  }, React.createElement(FormattedMessage, {
    id: "components.NotFound.index.2270254101",
    defaultMessage: "Powr\xF3t do strony g\u0142\xF3wnej"
  })))));
};

export default NotFound;